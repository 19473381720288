<template>
  <canvas id="mychart" width="400" height="150"></canvas>
</template>

<script>
export default {
  name: "overviewChart",
  props: ["dataSource", "id"],
  data() {
    return {
      dataCollection: null,
      labels: [],
      data: [],
    };
  },
  watch: {
    dataSource: {
      handler(newVal) {
        this.updateChartData(newVal)
      },
      deep: true
    }
  },
  methods: {
    updateChartData(_val){
      if(this.dataCollection) {
        // console.log('updateChartData')

        this.data = _val.data;
        this.labels = _val.labels;
        
        this.dataCollection.destroy()
        this.drawGraph()
      }
    },
    drawGraph() {
      let ctx = document.getElementById("mychart");
      let datasets = [];
      // if (this.id == "Exam") {
      //   datasets = [
      //     {
      //       label: "Lelaki",
      //       data: this.dataSource.data1,
      //       backgroundColor: "rgba(255, 99, 132)",
      //     },
      //     {
      //       label: "Perempuan",
      //       data: this.dataSource.data2,
      //       backgroundColor: "rgba(54, 162, 235)",
      //     },
      //   ];
      // } else {
      datasets = [
        {
          label: "Pengguna",
          data: this.data,
          backgroundColor: [
            "rgba(255,99,132,1)",
            "rgba(54, 162, 235, 1)",
            "rgba(255, 206, 86, 1)",
            "rgba(75, 192, 192, 1)",
            "rgba(153, 102, 255, 1)",
            "rgba(255, 159, 64, 1)",
            "rgba(55, 210, 249, 1)",
            "rgba(61 229 76,1)",
            "rgb(226, 226, 150)",
            "rgb(165 42 42)",
            "rgb(233 15 15)",
            "rgb(15 233 195)",
            "rgb(0 52 255)",
          ],
          borderColor: [
            "rgba(255,99,132,1)",
            "rgba(54, 162, 235, 1)",
            "rgba(255, 206, 86, 1)",
            "rgba(75, 192, 192, 1)",
            "rgba(153, 102, 255, 1)",
            "rgba(255, 159, 64, 1)",
            "rgba(55, 210, 249, 1)",
            "rgba(61 229 76,1)",
            "rgb(226, 226, 150)",
            "rgb(165 42 42)",
            "rgb(233 15 15)",
            "rgb(15 233 195)",
            "rgb(0 52 255)",
          ],
          borderWidth: 1,
        },
      ];
      // }
      this.dataCollection = new Chart(ctx, {
        type: "bar",
        data: {
          labels: this.labels,
          datasets,
        },
        options: {
          plugins: {
            legend: {
              display: false,
            },
          },
          responsive: true,
          interaction: {
            intersect: false,
          },
          scales: {
            x: {
              stacked: false,
              grid: {
                display: false,
                offset: true,
              },
            },
            y: {
              stacked: false,
              grid: {
                display: false,
                offset: true,
              },
            },
          },
        },
      });
    },
  },
  mounted() {
    this.data = this.dataSource.data;
    this.labels = this.dataSource.labels;
    this.drawGraph();
  },
  destroyed() {
    if (this.dataCollection != null) {
      this.dataCollection.destroy();
      this.dataCollection = null;
    }
  },
};
</script>