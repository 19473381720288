<template>
  <div class="card-body">
    <div class="row align-items-center mb-4">
      <div class="col-md-4">
        <div class="d-flex align-items-center">
          <input
            class="form-control me-2 input-custom"
            placeholder="Cari ..."
            v-model="searching"
          />
          <div class="dropdown me-2">
            <button
              class="btn btn-sm border dropdown-toggle"
              type="button"
              id="dropdownMenuFilter"
              data-bs-toggle="dropdown"
              data-bs-auto-close="outside"
              aria-expanded="false"
            >
              Saring
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuFilter">
              <li>
                <a
                  class="dropdown-item text-reset"
                  data-bs-toggle="collapse"
                  href="#collapseActionFilter"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseActionFilter"
                  >Jenis Tindakan</a
                >
                <div class="collapse" id="collapseActionFilter">
                  <div class="p-2 mb-2">
                    <select class="form-select" v-model="filterOption.action">
                      <option :value="null" disabled selected>
                        --Pilih tindakan--
                      </option>
                      <option
                        v-for="action in listActions"
                        :key="action"
                        :value="action"
                      >
                        {{ action }}
                      </option>
                    </select>
                  </div>
                </div>
              </li>
              <li>
                <a
                  class="dropdown-item text-reset"
                  data-bs-toggle="collapse"
                  href="#collapseDateFilter"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseDateFilter"
                  >Pilih tarikh</a
                >
                <div class="collapse" id="collapseDateFilter">
                  <div class="p-2 mb-2">
                    <v-date-picker v-model="filterOption.dateRange" is-range />
                    <!-- <button class="btn btn-sm btn-outline-secondary w-100 mt-1">Set</button> -->
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <button
            v-if="
              filterOption.userRole ||
              filterOption.action ||
              filterOption.dateRange.start ||
              filterOption.dateRange.end
            "
            class="btn btn-sm btn-outline-dark btn-sm"
            style="border-radius: 50%!important;"
            type="button"
            @click="resetLogs()"
          >
            X
          </button>
        </div>
      </div>
      <div class="col-md-8">
        <div class="d-flex align-items-center justify-content-end">
          <div class="btn-group" v-if="logs">
            <button
              class="btn btn-sm btn-outline-secondary me-2 btn-sm"
              @click="handleDownload"
            >
              Muat turun
            </button>
            <button
              type="button"
              class="btn btn-secondary btn-sm"
              v-if="currentPage != 1"
              @click="fetchLogs(-1)"
            >
              <i class="bi bi-chevron-left"></i>
              Sebelum
            </button>
            <button
              type="button"
              class="btn btn-outline-success ms-2 me-2 btn-sm"
            >
              Page {{ currentPage }}
            </button>
            <button
              type="button"
              class="btn btn-secondary btn-sm"
              v-if="!(logs.length < pageSize) && logs.length !== 0"
              @click="fetchLogs(1)"
            >
              Seterusnya
              <i class="bi bi-chevron-right"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="w-100 table-fixHead h-100">
      <table
        class="table table-borderless table-sm w-100 table-hover"
        style="table-layout: fixed"
      >
        <thead>
          <tr>
            <th style="width: 10%">Date</th>
            <th style="width: 10%">Action</th>
            <th style="width: 15%">Item</th>
            <th>Initial Data</th>
            <th>Updated Data</th>
            <th>User</th>
          </tr>
        </thead>
        <tbody v-if="!isLoading && logs">
          <tr
            v-for="(log, index) in logs"
            :key="index"
            class="text-start cursor-pointer"
          >
            <td class="text-muted">
              <p>{{ setDate(log.attributes.timestamp) }}</p>
            </td>
            <td class="text-muted">
              <p>
                {{
                  `${setMethod(log.attributes.action.split(" ")[0])} ${
                    log.attributes.action.split(" ")[1]
                      ? log.attributes.action.split(" ")[1]
                      : ""
                  }`
                }}
              </p>
            </td>
            <td class="text-muted">
              <p>{{ log.attributes.target }}</p>
            </td>
            <td class="fst-italic text-muted">
              <p style="font-family: monospace, monospace">
                {{
                  log.attributes.initial_data
                    ? JSON.stringify(log.attributes.initial_data, null, 4)
                    : "NA"
                }}
              </p>
            </td>
            <td class="fst-italic text-muted" @click="log.toggle = !log.toggle">
              <p style="font-family: monospace, monospace" :class="{'toggle-view-more' : log.toggle}">
                {{ log.toggle 
                  ? (log.attributes.updated_data 
                    ? JSON.stringify(log.attributes.updated_data, null, 4)
                    : "NA")
                  : "View More"
                }}
              </p>
            </td>
            <td class="fst-italic text-muted">
              <p style="font-family: monospace, monospace">
                {{
                  log.attributes.user
                    ? log.attributes.user.full_name ||
                      log.attributes.user.firstname
                    : "NA"
                }}
              </p>
            </td>
          </tr>
        </tbody>
        <tbody v-else-if="isLoading">
          <tr>
            <div class="text-center">
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import qs from "qs";
import moment from "moment";
import API from "../../../utils/API";
import * as XLSX from "xlsx";
export default {
  data() {
    return {
      logs: null,
      isLoading: false,
      defaultLogs: null,
      searching: "",
      filterOption: {
        userRole: null,
        action: null,
        dateRange: {
          start: null,
          end: null,
        },
      },
      filters: {},
      listActions: ["Create new", "Edit", "Delete"],
      listRoles: [],
      currentPage: 1,
      pageSize: 30,
    };
  },
  watch: {
    searching: {
      handler(_e) {
        if (this.searching.length > 0) {
          this.logs = this.defaultLogs.filter((x) => {
            if (x.attributes.user)
              if (x.attributes.user.full_name)
                return x.attributes.user.full_name
                  .toLowerCase()
                  .includes(this.searching);
              else if (x.attributes.user.firstname)
                return x.attributes.user.firstname
                  .toLowerCase()
                  .includes(this.searching);
              else return null;
          });
        }
      },
    },
    filterOption: {
      async handler(_e) {
        let filters = {}
        if (_e.action) {
          let method = this.getMethod(_e.action);

          filters.action = {
              $containsi: method
          }
        }

        if (_e.dateRange.start && _e.dateRange.end) {
          let startDate = moment(this.filterOption.dateRange.start).format(
            "YYYY-MM-DD"
          );
          let endDate = moment(this.filterOption.dateRange.end).format(
            "YYYY-MM-DD"
          );

          filters.createdAt = {
            $lte: endDate,
            $gte: startDate
          }
          // let params = `&filters[$and][0][createdAt][$lte]=${endDate}&filters[$and][1][createdAt][$gte]=${startDate}`;
          // await this.getCustomLogs(params);
        }

        this.fetchLogs(0, filters)
      },
      deep: true,
    },
    logs: {
      async handler(_e) {

      },
      deep: true
    }
  },
  methods: {
    fetchLogs(_page, _filters){
      return new Promise(async(resolve, reject) => {
        this.isLoading = true;
        this.currentPage = this.currentPage + _page;
        
        //includes filter
        if(_filters) this.filters = _filters

        const query = qs.stringify(
          {
            filters: this.filters,
            // populate: [],
            sort: [
              "timestamp:desc",
            ],
            pagination: {
              pageSize: this.pageSize,
              page: this.currentPage,
              withCount: true
            },
          },
          {
            encodeValuesOnly: true,
          }
        );
        const res = await API.get(`audit-logs?${query}`);
        // const params =
        //   "?pagination[pageSize]=10&pagination[page]=1&pagination[withCount]=true&sort[0]=timestamp%3Adesc";
        // const res = await API.get("audit-logs" + params);

        this.defaultLogs = res.data.data;
        this.logs = res.data.data.map(i => ({ ...i, toggle: false })); //add toggle attr

        this.isLoading = false;
        resolve(0)
      })
    },
    setDate(date) {
      return moment(date).format("DD/MM/YYYY HH:mm:ss");
    },
    objectToString(obj) {
      let array = [];
      for (const [key, value] of Object.entries(obj)) {
        if (typeof value !== "object") array.push(` ${key}: ${value}`);
      }
      return array.join(", \n");
    },
    setMethod(method) {
      let displayMethod = "";
      switch (method) {
        case "PUT":
          // code block
          displayMethod = "Edit";
          break;
        case "POST":
          // code block
          displayMethod = "Create new";
          break;
        case "DELETE":
          // code block
          displayMethod = "Delete";
          break;
        default:
          displayMethod = "Edit";
        // code block
      }
      return displayMethod;
    },
    getMethod(_diplayMethod) {
      let method = "";
      switch (_diplayMethod) {
        case "Edit":
          // code block
          method = "PUT";
          break;
        case "Create new":
          // code block
          method = "POST";
          break;
        case "Delete":
          // code block
          method = "DELETE";
          break;
        default:
          method = "PUT";
        // code block
      }
      return method;
    },
    loadLogs() {
      let finalLogs = this.logs;

      if (this.searching.length > 0) {
        finalLogs = finalLogs.filter((x) => {
          if (x.attributes.user)
            if (x.attributes.user.full_name)
              return x.attributes.user.full_name
                .toLowerCase()
                .includes(this.searching);
            else if (x.attributes.user.firstname)
              return x.attributes.user.firstname
                .toLowerCase()
                .includes(this.searching);
            else return null;
        });
      }

      if (this.filterOption.action) {
        let method = this.getMethod(this.filterOption.action);
        finalLogs = finalLogs.filter((x) => {
          return x.attributes.action
            .toLowerCase()
            .includes(method.toLowerCase());
        });
      }

      return finalLogs;
    },
    getCustomLogs(_params) {
      return new Promise(async (resolve, reject) => {
        const params =
          "?pagination[pageSize]=100&pagination[page]=1&pagination[withCount]=true&sort[0]=timestamp%3Adesc" +
          _params;
        const res = await API.get("audit-logs" + params);
        this.logs = res.data.data;
        resolve(0);
      });
    },
    async resetLogs() {
      this.filterOption.userRole = null;
      this.filterOption.action = null;
      this.filterOption.dateRange = { start: null, end: null };
      this.filters = {}

      await this.fetchLogs(0)
      // this.logs = this.defaultLogs;
    },
    handleDownload() {
      const headers = [
        "#",
        "Date",
        "Action",
        "Item",
        "Initial Data",
        "Updated Data",
        "User",
      ];

      let data4Excel = this.logs.map((log, ind) => {
        return [
          ind + 1,
          this.setDate(log.attributes.timestamp),
          `${this.setMethod(log.attributes.action.split(" ")[0])} ${
            log.attributes.action.split(" ")[1]
              ? log.attributes.action.split(" ")[1]
              : ""
          }`,
          log.attributes.target,
          log.attributes.initial_data
            ? JSON.stringify(log.attributes.initial_data, null, 4)
            : "NA",
          log.attributes.updated_data
            ? JSON.stringify(log.attributes.updated_data, null, 4)
            : "NA",
          log.attributes.user
            ? log.attributes.user.full_name || log.attributes.user.firstname
            : "NA",
        ];
      });

      data4Excel = [headers, ...data4Excel];
      const ws = XLSX.utils.aoa_to_sheet(data4Excel);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
      /* generate file and send to client */
      XLSX.writeFile(wb, "senarai_logs.xlsx");
    },
  },
  async mounted() {
    await this.fetchLogs(0)

    const res02 = await API.get("users-permissions/roles");
    this.listRoles = res02.data.roles;
  },
};
</script>

<style scoped>
.input-custom {
  width: 200px;
}
.toggle-view-more {
  background-color: #fafa71;
  padding: 10px 10px;
  border-radius: 10px;
}
</style>
