<template>
  <div>
    <div
      class="card-body border-bottom border-4 border-light"
      v-if="chartData != null"
    >
      <overview-chart :id="'Kursus'" :dataSource="chartData" />
    </div>
    <div
      v-else
      class="card-body border-bottom border-4 border-light w-100 d-flex position-relative justify-content-center align-items-center"
      style="height: 394px"
    >
      <div class="text-center">
        <div class="spinner-grow text-muted" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        <p class="text-muted small mt-2">Sila tunggu. Memuatkan data...</p>
      </div>
    </div>
    <div class="card-body">
      <div class="d-flex justify-content-between align-items-center mb-2">
        <div class="d-flex">
          <input
            class="form-control me-2 input-custom"
            placeholder="Cari ..."
            v-model="searching"
          />
          <div class="dropdown me-2">
            <button
              class="btn btn-sm border dropdown-toggle"
              type="button"
              id="dropdownMenuFilter"
              data-bs-toggle="dropdown"
              data-bs-auto-close="outside"
              aria-expanded="false"
            >
              Saring
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuFilter">
              <li>
                <a
                  class="dropdown-item text-reset"
                  data-bs-toggle="collapse"
                  href="#collapseStateFilter"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseStateFilter"
                  >Negeri</a
                >
                <div class="collapse" id="collapseStateFilter">
                  <div class="p-2 mb-2">
                    <select class="form-select" v-model="filterOption.state">
                      <option :value="null" disabled selected hidden>
                        Pilih Negeri
                      </option>
                      <option
                        v-for="state in states"
                        :key="state"
                        :value="state"
                      >
                        {{ state }}
                      </option>
                    </select>
                  </div>
                </div>
              </li>
              <li>
                <a
                  class="dropdown-item text-reset"
                  data-bs-toggle="collapse"
                  href="#collapseGenderFilter"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseGenderFilter"
                  >Jantina</a
                >
                <div class="collapse" id="collapseGenderFilter">
                  <div class="p-2 mb-2">
                    <select class="form-select" v-model="filterOption.gender">
                      <option :value="null" disabled selected hidden>
                        Pilih Jantina
                      </option>
                      <option value="lelaki">Lelaki</option>
                      <option value="perempuan">Perempuan</option>
                    </select>
                  </div>
                </div>
              </li>
              <li>
                <a
                  class="dropdown-item text-reset"
                  data-bs-toggle="collapse"
                  href="#collapseAgeFilter"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseAgeFilter"
                  >Umur</a
                >
                <div class="collapse" id="collapseAgeFilter">
                  <div class="d-flex p-2">
                    <input
                      class="form-control me-2 input-custom"
                      placeholder="Min umur"
                      type="number"
                      v-model="filterOption.age.min"
                    />
                    <input
                      class="form-control me-2 input-custom"
                      placeholder="Max umur"
                      type="number"
                      v-model="filterOption.age.max"
                    />
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <button
            v-if="
              filterOption.state ||
              filterOption.gender ||
              filterOption.age.min ||
              filterOption.age.max
            "
            class="btn btn-sm border"
            type="button"
            @click="
              () => {
                filterOption.state = null;
                filterOption.gender = null;
                filterOption.age.min = null;
                filterOption.age.max = null;
              }
            "
          >
            Set semula
          </button>
        </div>
        <div class="d-flex align-items-center">
          <button
            type="button"
            class="btn btn-sm border me-2"
            :disabled="findNameUsers().length == 0"
            @click="handleDownload"
          >
            Muat turun
          </button>
          <button
            type="button"
            class="btn btn-sm border"
            @click="handlePrint"
            :disabled="findNameUsers().length == 0"
          >
            Cetak
          </button>
        </div>
      </div>
      <div class="tableFixHead">
        <div class="d-flex flex-row-reverse mt-3 mb-2 me-1">
          <div class="justify-content-center">
            <small class="text-muted"
              >Jumlah pengguna: {{ findNameUsers().length }}</small
            >
          </div>
        </div>
        <table class="table">
          <thead>
            <tr>
              <th style="width: 30px">#</th>
              <th style="width: 300px">Nama</th>
              <th style="width: 100px">Daerah</th>
              <th style="width: 150px">Negeri</th>
              <th style="width: 100px">Umur</th>
              <th style="width: 100px">Jantina</th>
              <th style="width: 300px">Kursus</th>
            </tr>
          </thead>
          <tbody class="border-0">
            <tr
              class="border-light"
              v-for="(user, idx) in findNameUsers()"
              :key="idx"
            >
              <td>{{ idx + 1 }}</td>
              <!-- <td>{{ user.username ? user.username : "-" }}</td> -->
              <td>{{ user.full_name ? user.full_name : "-" }}</td>
              <td>{{ user.district ? user.district : "-" }}</td>
              <td>{{ user.state ? user.state : "-" }}</td>
              <td>{{ user.age ? user.age : "-" }}</td>
              <td>{{ user.gender ? user.gender : "-" }}</td>
              <td>
                <div v-if="user.user_course_completions.length == 0">-</div>
                <div
                  v-else
                  class="d-flex justify-content-between align-items-center"
                  v-for="(item, i) in user.user_course_completions"
                  :key="i"
                >
                  <div class="me-2 mb-2" style="font-size: 0.8rem">
                    {{ item.course.course_name }}
                  </div>
                  <div
                    :class="
                      item.completion_percentage == 100
                        ? 'badge rounded-pill bg-success text-white mb-2'
                        : item.completion_percentage < 50
                        ? 'badge rounded-pill bg-light text-muted mb-2'
                        : 'badge rounded-pill bg-warning text-white mb-2'
                    "
                    style="font-size: 0.8rem"
                  >
                    {{ item.completion_percentage }}%
                  </div>
                </div>
              </td>
            </tr>
            <!-- <tr></tr> -->
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import API from "../../../utils/API";
import overviewChart from "./../Chart/overviewChart.vue";
import * as XLSX from "xlsx";
import printJS from "print-js";

export default {
  name: "KursusBakalHaji",
  components: {
    overviewChart,
  },
  data() {
    return {
      courses: [],
      chartData: null,
      searching: "",
      users: [],
      states: [
        "Johor",
        "Kedah",
        "Kelantan",
        "Melaka",
        "Negeri Sembilan",
        "Pahang",
        "Perak",
        "Perlis",
        "Pulau Pinang",
        "Sarawak",
        "Selangor",
        "Terengganu",
        "Kuala Lumpur",
        "Labuan",
        "Sabah",
        "Putrajaya",
      ],
      filterOption: {
        state: null,
        gender: null,
        age: {
          min: null,
          max: null,
        },
      },
    };
  },
  watch: {
    // filterOption: {
    //   handler(_val){
    //     console.log('val :', _val)
    //   },
    //   deep: true
    // }
  },
  methods: {
    handleDownload() {
      let headers = [
        "#",
        "Nama",
        "Daerah",
        "Negeri",
        "Umur",
        "Jantina",
        "Kursus",
      ];
      let rowData = this.findNameUsers().map((user, index) => {
        let kursusData = [];
        if (user.user_course_completions.length > 0) {
          kursusData = user.user_course_completions.map((item) => {
            return `${item.course.course_name} - ${item.completion_percentage}`;
          });
        }
        return [
          index + 1,
          user.username ? user.username : "-",
          user.district ? user.district : "-",
          user.state ? user.state : "-",
          user.age ? user.age : "-",
          user.gender ? user.gender : "-",
          kursusData.join(),
        ];
      });
      let data = [headers, ...rowData];
      const ws = XLSX.utils.aoa_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
      /* generate file and send to client */
      XLSX.writeFile(wb, "sheetjs.xlsx");
    },
    handlePrint() {
      printJS({
        printable: this.findNameUsers().map((user, index) => {
          let kursusData = [];
          if (user.user_course_completions.length > 0) {
            kursusData = user.user_course_completions.map((item) => {
              return `${item.course.course_name} - ${item.completion_percentage}`;
            });
          }
          return {
            index: index + 1,
            // name: user.username ? user.username : "-",
            name: user.full_name ? user.full_name : "-",
            daerah: user.district ? user.district : "-",
            negeri: user.state ? user.state : "-",
            umur: user.age ? user.age : "-",
            jantina: user.gender ? user.gender : "-",
            kursus: kursusData.join(),
          };
        }),
        properties: [
          { field: "index", displayName: "#" },
          { field: "name", displayName: "Nama" },
          { field: "daerah", displayName: "Daerah" },
          { field: "negeri", displayName: "Negeri" },
          { field: "umur", displayName: "Umur" },
          { field: "jantina", displayName: "Jantina" },
          { field: "kursus", displayName: "Kursus" },
        ],
        type: "json",
      });
    },
    findNameUsers() {
      let finalUsers = this.users;

      if (this.searching.length > 0) {
        finalUsers = finalUsers.filter((x) => {
          if (x.full_name != null)
            return x.full_name.toLowerCase().includes(this.searching.toLowerCase());
          else return null;
        });
      }

      if (this.filterOption.state) {
        finalUsers = finalUsers.filter((x) => {
          if (x.state != null)
            return x.state
              .toLowerCase()
              .includes(this.filterOption.state.toLowerCase());
        });
      }

      if (this.filterOption.gender) {
        finalUsers = finalUsers.filter((x) => {
          if (x.gender != null)
            return x.gender
              .toLowerCase()
              .includes(this.filterOption.gender.toLowerCase());
        });
      }

      if (this.filterOption.age.min && this.filterOption.age.max) {
        finalUsers = finalUsers.filter((x) => {
          if (x.age != null)
            return (
              x.age >= this.filterOption.age.min &&
              x.age <= this.filterOption.age.max
            );
        });
      }

      return finalUsers;
    },
    getUser() {
      API.get(
        "users?[populate][0]=user_course_completions&populate[user_course_completions][populate][0]=course&populate[role]=role&filters[$and][0][confirmed][$eq]=true&filters[$and][1][blocked][$eq]=false&limit=10000"
      )
        .then((response) => {
          this.users = response.data.filter((x) => {
            // console.log('x : ', x)
            if (x.role && x.role.name == "BH") return x;
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCourses() {
      API.get(
        `courses?[populate]=users&filters[$and][0][role_access][$eq]=public&filters[$and][0][role_access][$eq]=bh&filters[$and][0][course_type][$eq]=online`
        // `courses?[populate]=users
        //         &filters[role_access][$in]=public
        //         &filters[role_access][$in]=bh
        //         &filters[course_type][$eq]=online
        // `
      )
        .then((response) => {
          this.selectedCourse = response.data.data[0].id;
          this.courses = response.data.data;

          if (response.data.data.length > 0) {
            this.chartData = {
              labels: response.data.data.map((x) => {
                return x.attributes.course_name;
              }),
              data: response.data.data.map((x) => {
                // return x.attributes.users.data.length;
                let userData = x.attributes.users.data.filter(
                  (i) => i.attributes.confirmed && !i.attributes.bloked
                );
                return userData.length;
              }),
            };
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getGraphData(){
      const res01 = await API.post(`custom/fetch-graph-data`, { graph_type: "bh_kursus" })
      this.chartData = res01.data.result
    }
  },
  mounted() {
    // this.getCourses();
    this.getGraphData();
    this.getUser();
  },
};
</script>

<style scoped>
.body-container {
  min-height: calc(100vh - 170px);
}

.list-group-item {
  cursor: pointer;
}

.inactive {
  color: #9aa0ad;
}

.is-active {
  color: #4ca022;
}

.card-custom {
  position: relative;
  display: block;
  height: 40px;
}

.img-container {
  position: inherit;
  display: block;
  height: 100%;
  width: 100%;
  background-image: url("../../../assets/img/file-icon.svg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
}

.col-3.col-custom {
  min-width: 250px !important;
}

.input-custom {
  width: 200px;
}

.tableFixHead {
  overflow: auto;
  min-height: 200px;
  max-height: 500px;
}
.tableFixHead thead th {
  position: sticky;
  top: 0;
  z-index: 1;
}

/* Just common table stuff. Really. */
table {
  border-collapse: collapse;
  width: 100%;
}
th,
td {
  padding: 8px 16px;
}
th {
  background: #f9fafb;
}

::-webkit-scrollbar {
  display: none !important;
}
</style>
