var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center mb-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searching),expression:"searching"}],staticClass:"form-control me-2 input-custom",attrs:{"placeholder":"Search ..."},domProps:{"value":(_vm.searching)},on:{"input":function($event){if($event.target.composing){ return; }_vm.searching=$event.target.value}}}),_c('div',{staticClass:"d-flex align-items-center"},[_c('button',{staticClass:"btn btn-sm border me-2",attrs:{"type":"button","disabled":_vm.findPekta().length == 0},on:{"click":_vm.handleDownload}},[_vm._v(" Download ")]),_c('button',{staticClass:"btn btn-sm border",attrs:{"type":"button","disabled":_vm.findPekta().length == 0},on:{"click":_vm.handlePrint}},[_vm._v(" Print ")])])]),_c('div',{staticClass:"tableFixHead"},[_c('table',{staticClass:"table"},[_vm._m(0),_c('tbody',{staticClass:"border-0"},_vm._l((_vm.findPekta()),function(user,idx){return _c('tr',{key:idx,staticClass:"border-light"},[_c('td',[_vm._v(_vm._s(idx + 1))]),_c('td',[_vm._v(" "+_vm._s(user.attributes.user && user.attributes.user.username ? user.attributes.user.username : "-")+" ")]),_c('td',[_vm._v(" "+_vm._s(user.attributes.user && user.attributes.user.age ? user.attributes.user.age : "-")+" ")]),_c('td',[_vm._v(" "+_vm._s(user.attributes.user && user.attributes.user.gender ? user.attributes.user.gender : "-")+" ")]),_c('td',[(
                  user.attributes.user &&
                  user.attributes.user.user_course_completions.length == 0
                )?_c('div',[_vm._v(" - ")]):(
                  user.attributes.user &&
                  user.attributes.user.user_course_completions
                )?_vm._l((user.attributes.user
                  .user_course_completions),function(item,i){return _c('div',{key:i,staticClass:"d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"me-2 mb-2",staticStyle:{"font-size":"0.8rem"}},[_vm._v(" "+_vm._s(item.course.data.attributes.course_name)+" ")]),_c('div',{class:item.completion_percentage == 100
                      ? 'badge rounded-pill bg-success text-white mb-2'
                      : item.completion_percentage < 50
                      ? 'badge rounded-pill bg-light text-muted mb-2'
                      : 'badge rounded-pill bg-warning text-white mb-2',staticStyle:{"font-size":"0.8rem"}},[_vm._v(" "+_vm._s(item.completion_percentage)+"% ")])])}):_vm._e()],2),_c('td',[_vm._v("-")]),_c('td',[_vm._v("-")])])}),0)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{staticStyle:{"width":"30px"}},[_vm._v("#")]),_c('th',{staticStyle:{"width":"300px"}},[_vm._v("Nama")]),_c('th',{staticStyle:{"width":"100px"}},[_vm._v("Umur")]),_c('th',{staticStyle:{"width":"100px"}},[_vm._v("Jantina")]),_c('th',{staticStyle:{"width":"400px"}},[_vm._v("Kursus")]),_c('th',{staticStyle:{"width":"100px"}},[_vm._v("Markah")]),_c('th',{staticStyle:{"width":"100px"}},[_vm._v("Status")])])])}]

export { render, staticRenderFns }