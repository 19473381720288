<template>
  <div class="card border-0">
    <div class="card-body">
      <ul class="nav nav-tabs">
        <li
          class="nav-item"
          @click="activeTabSenarai = 'Baru'"
          style="cursor: pointer"
        >
          <a
            :class="
              activeTabSenarai == 'Baru'
                ? 'nav-link active'
                : 'nav-link text-muted'
            "
            >Baru</a
          >
        </li>
        <li
          class="nav-item"
          @click="activeTabSenarai = 'Berdaftar'"
          style="cursor: pointer"
        >
          <a
            :class="
              activeTabSenarai == 'Berdaftar'
                ? 'nav-link active'
                : 'nav-link text-muted'
            "
            aria-current="page"
            >Berdaftar</a
          >
        </li>
        <!-- <li
                      class="nav-item"
                      @click="activeTabSenarai = 'Tafaqquh'"
                      style="cursor: pointer"
                    >
                      <a
                        :class="
                          activeTabSenarai == 'Tafaqquh'
                            ? 'nav-link active'
                            : 'nav-link text-muted'
                        "
                        >Tafaqquh</a
                      >
                    </li> -->
      </ul>
    </div>
    <div v-if="activeTabSenarai == 'Berdaftar'">
      <div
        class="card-body border-bottom border-4 border-light"
        v-if="chartDataDiDaftar != null"
      >
        <overview-chart :id="'chart_senarai'" :dataSource="chartDataDiDaftar" />
      </div>
      <div
        v-else
        class="card-body border-bottom border-4 border-light w-100 d-flex position-relative justify-content-center align-items-center"
        style="height: 394px"
      >
        <div class="text-center">
          <div class="spinner-grow text-muted" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <p class="text-muted small mt-2">Sila tunggu. Memuatkan data...</p>
        </div>
      </div>
      <div class="card-body">
        <div class="d-flex justify-content-between align-items-center mb-2">
          <div class="d-flex">
            <input
              class="form-control me-2 input-custom"
              placeholder="Cari ..."
              v-model="searchingDiDafter"
            />
            <div class="dropdown me-2">
              <button
                class="btn btn-sm border dropdown-toggle"
                type="button"
                id="dropdownMenuFilter"
                data-bs-toggle="dropdown"
                data-bs-auto-close="outside"
                aria-expanded="false"
              >
                Saring
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuFilter">
                <li>
                  <a
                    class="dropdown-item text-reset"
                    data-bs-toggle="collapse"
                    href="#collapseStateFilter"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseStateFilter"
                    >Negeri</a
                  >
                  <div class="collapse" id="collapseStateFilter">
                    <div class="p-2 mb-2">
                      <select class="form-select" v-model="filterOption.state">
                        <option :value="null" disabled selected hidden>
                          Pilih Negeri
                        </option>
                        <option
                          v-for="state in states"
                          :key="state"
                          :value="state"
                        >
                          {{ state }}
                        </option>
                      </select>
                    </div>
                  </div>
                </li>
                <li>
                  <a
                    class="dropdown-item text-reset"
                    data-bs-toggle="collapse"
                    href="#collapseGenderFilter"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseGenderFilter"
                    >Jantina</a
                  >
                  <div class="collapse" id="collapseGenderFilter">
                    <div class="p-2 mb-2">
                      <select class="form-select" v-model="filterOption.gender">
                        <option :value="null" disabled selected hidden>
                          Pilih Jantina
                        </option>
                        <option value="lelaki">Lelaki</option>
                        <option value="perempuan">Perempuan</option>
                      </select>
                    </div>
                  </div>
                </li>
                <li>
                  <a
                    class="dropdown-item text-reset"
                    data-bs-toggle="collapse"
                    href="#collapseStatusFilter"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseStatusFilter"
                    >Status</a
                  >
                  <div class="collapse" id="collapseStatusFilter">
                    <div class="p-2 mb-2">
                      <select class="form-select" v-model="filterOption.status">
                        <option :value="null" disabled selected hidden>
                          Pilih Status
                        </option>
                        <option value="pending">Menunggu Tindakan</option>
                        <option value="processing">Dalam Proses</option>
                        <option value="approved">Diluluskan</option>
                        <option value="rejected">Ditolak</option>
                      </select>
                    </div>
                  </div>
                </li>
                <li>
                  <a
                    class="dropdown-item text-reset"
                    data-bs-toggle="collapse"
                    href="#collapseYearFilter"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseYearFilter"
                    >Tahun Lantikan</a
                  >
                  <div class="collapse" id="collapseYearFilter">
                    <select class="form-select" v-model="filterOption.year">
                      <option :value="null" disabled selected hidden>
                        Pilih Tahun
                      </option>
                      <option
                        v-for="year in generateYears(1990, 2050)"
                        :key="year"
                        :value="year"
                      >
                        {{ year }}
                      </option>
                    </select>
                  </div>
                </li>
                <li>
                  <a
                    class="dropdown-item text-reset"
                    data-bs-toggle="collapse"
                    href="#collapseAgeFilter"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseAgeFilter"
                    >Umur</a
                  >
                  <div class="collapse" id="collapseAgeFilter">
                    <div class="d-flex p-2">
                      <input
                        class="form-control me-2 input-custom"
                        placeholder="Min umur"
                        type="number"
                        v-model="filterOption.age.min"
                      />
                      <input
                        class="form-control me-2 input-custom"
                        placeholder="Max umur"
                        type="number"
                        v-model="filterOption.age.max"
                      />
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <button
              v-if="
                filterOption.state ||
                filterOption.gender ||
                filterOption.status ||
                filterOption.age.min ||
                filterOption.age.max ||
                filterOption.year
              "
              class="btn btn-sm border"
              type="button"
              @click="
                () => {
                  filterOption.state = null;
                  filterOption.gender = null;
                  filterOption.status = null;
                  filterOption.year = null;
                  filterOption.age.min = null;
                  filterOption.age.max = null;
                }
              "
            >
              Set semula
            </button>
          </div>
          <div class="d-flex align-items-center">
            <button
              type="button"
              class="btn btn-sm border me-2"
              @click="handleDownload('Berdaftar')"
              :disabled="findNamePektaDiDaftar().length == 0"
            >
              Muat turun
            </button>
            <button
              type="button"
              class="btn btn-sm border"
              @click="handlePrint('Berdaftar')"
              :disabled="findNamePektaDiDaftar().length == 0"
            >
              Cetak
            </button>
          </div>
        </div>
        <div class="tableFixHead">
          <div class="d-flex flex-row-reverse mt-3 mb-2 me-1">
            <div class="justify-content-center">
              <small class="text-muted"
                >Jumlah pengguna: {{ findNamePektaDiDaftar().length }}</small
              >
            </div>
          </div>
          <table class="table">
            <thead>
              <tr>
                <th style="width: 30px">#</th>
                <th style="width: 300px">Nama</th>
                <th style="width: 100px">Daerah</th>
                <th style="width: 100px">Negeri</th>
                <th style="width: 100px">Tahun Lantikan</th>
                <th style="width: 100px">Jantina</th>
                <!-- <th style="width: 300px">Kelayakan</th> -->
                <th style="width: 100px">Status</th>
              </tr>
            </thead>
            <tbody class="border-0">
              <tr
                class="border-light"
                v-for="(pekta, idx) in findNamePektaDiDaftar()"
                :key="idx"
              >
                <td>{{ idx + 1 }}</td>
                <td>
                  {{ pekta.full_name ? pekta.full_name.toUpperCase() : "-" }}
                </td>
                <td>
                  {{ pekta.district ? pekta.district.toUpperCase() : "-" }}
                </td>
                <td>
                  {{ pekta.state ? pekta.state.toUpperCase() : "-" }}
                </td>
                <td scope="row">
                  {{
                      pekta.registration_number
                        ? pekta.registration_number.substring(0, 4)
                        : "-"
                  }}
                </td>
                <td>
                  {{ pekta.gender ? pekta.gender : "-" }}
                </td>
                <!-- <td>
                  <div>
                    <div
                      class="d-flex"
                      style="font-size: 0.8rem"
                      v-for="(qualification, i) in qualifications(pekta)"
                      :key="i"
                    >
                      <p class="mb-0 me-1">
                        {{
                          qualification.level
                            ? helper.snakeToTitleCase(qualification.level)
                            : ""
                        }}
                      </p>
                      <p class="fw-bold">
                        {{
                          qualification.type
                            ? `(${helper.snakeToTitleCase(qualification.type)})`
                            : ""
                        }}
                      </p>
                    </div>
                  </div>
                </td> -->
                <td>
                  <div
                    :class="[
                      'badge',
                      'rounded-pill',
                      getStatus(pekta) === 'Diluluskan' ? 'bg-success' : 
                        getStatus(pekta) === 'Menunggu Tindakan' ? 'bg-warning' : 
                        getStatus(pekta) === 'Ditolak' ? 'bg-danger' : 
                        getStatus(pekta) === 'Dalam Proses' ? 'bg-warning' : '',
                      'text-white'
                    ]"
                  >
                    {{getStatus(pekta)}}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div v-if="activeTabSenarai == 'Baru'">
      <div
        class="card-body border-bottom border-4 border-light"
        v-if="chartDataBaru != null"
      >
        <overview-chart :id="'chart_senarai'" :dataSource="chartDataBaru" />
      </div>
      <div
        v-else
        class="card-body border-bottom border-4 border-light w-100 d-flex position-relative justify-content-center align-items-center"
        style="height: 394px"
      >
        <div class="text-center">
          <div class="spinner-grow text-muted" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <p class="text-muted small mt-2">Sila tunggu. Memuatkan data...</p>
        </div>
      </div>
      <div class="card-body">
        <div class="d-flex justify-content-between align-items-center mb-2">
          <div class="d-flex">
            <input
              class="form-control me-2 input-custom"
              placeholder="Cari ..."
              v-model="searchingBaru"
            />
            <div class="dropdown me-2">
              <button
                class="btn btn-sm border dropdown-toggle"
                type="button"
                id="dropdownMenuFilter"
                data-bs-toggle="dropdown"
                data-bs-auto-close="outside"
                aria-expanded="false"
              >
                Saring
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuFilter">
                <li>
                  <a
                    class="dropdown-item text-reset"
                    data-bs-toggle="collapse"
                    href="#collapseStateFilter"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseStateFilter"
                    >Negeri</a
                  >
                  <div class="collapse" id="collapseStateFilter">
                    <div class="p-2 mb-2">
                      <select class="form-select" v-model="filterOption.state">
                        <option :value="null" disabled selected hidden>
                          Pilih Negeri
                        </option>
                        <option
                          v-for="state in states"
                          :key="state"
                          :value="state"
                        >
                          {{ state }}
                        </option>
                      </select>
                    </div>
                  </div>
                </li>
                <li>
                  <a
                    class="dropdown-item text-reset"
                    data-bs-toggle="collapse"
                    href="#collapseGenderFilter"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseGenderFilter"
                    >Jantina</a
                  >
                  <div class="collapse" id="collapseGenderFilter">
                    <div class="p-2 mb-2">
                      <select class="form-select" v-model="filterOption.gender">
                        <option :value="null" disabled selected hidden>
                          Pilih Jantina
                        </option>
                        <option value="lelaki">Lelaki</option>
                        <option value="perempuan">Perempuan</option>
                      </select>
                    </div>
                  </div>
                </li>
                <li>
                  <a
                    class="dropdown-item text-reset"
                    data-bs-toggle="collapse"
                    href="#collapseStatusFilter"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseStatusFilter"
                    >Status</a
                  >
                  <div class="collapse" id="collapseStatusFilter">
                    <div class="p-2 mb-2">
                      <select class="form-select" v-model="filterOption.status">
                        <option :value="null" disabled selected hidden>
                          Pilih Status
                        </option>
                        <option value="pending">Menunggu Tindakan</option>
                        <option value="processing">Dalam Proses</option>
                        <option value="approved">Diluluskan</option>
                        <option value="rejected">Ditolak</option>
                      </select>
                    </div>
                  </div>
                </li>
                <li>
                  <a
                    class="dropdown-item text-reset"
                    data-bs-toggle="collapse"
                    href="#collapseYearFilter"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseYearFilter"
                    >Tahun Lantikan</a
                  >
                  <div class="collapse" id="collapseYearFilter">
                    <select class="form-select" v-model="filterOption.year">
                      <option :value="null" disabled selected hidden>
                        Pilih Tahun
                      </option>
                      <option
                        v-for="year in generateYears(1990, 2050)"
                        :key="year"
                        :value="year"
                      >
                        {{ year }}
                      </option>
                    </select>
                  </div>
                </li>
                <li>
                  <a
                    class="dropdown-item text-reset"
                    data-bs-toggle="collapse"
                    href="#collapseAgeFilter"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseAgeFilter"
                    >Umur</a
                  >
                  <div class="collapse" id="collapseAgeFilter">
                    <div class="d-flex p-2">
                      <input
                        class="form-control me-2 input-custom"
                        placeholder="Min umur"
                        type="number"
                        v-model="filterOption.age.min"
                      />
                      <input
                        class="form-control me-2 input-custom"
                        placeholder="Max umur"
                        type="number"
                        v-model="filterOption.age.max"
                      />
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <button
              v-if="
                filterOption.state ||
                filterOption.gender ||
                filterOption.status ||
                filterOption.age.min ||
                filterOption.age.max ||
                filterOption.year
              "
              class="btn btn-sm border"
              type="button"
              @click="
                () => {
                  filterOption.state = null;
                  filterOption.gender = null;
                  filterOption.status = null;
                  filterOption.age.min = null;
                  filterOption.age.max = null;
                  filterOption.year = null;
                }
              "
            >
              Set semula
            </button>
          </div>
          <div class="d-flex align-items-center">
            <button
              type="button"
              class="btn btn-sm border me-2"
              @click="handleDownload('Baru')"
              :disabled="findNamePektaBaru().length == 0"
            >
              Muat turun
            </button>
            <button
              type="button"
              class="btn btn-sm border"
              @click="handlePrint('Baru')"
              :disabled="findNamePektaBaru().length == 0"
            >
              Cetak
            </button>
          </div>
        </div>
        <div class="tableFixHead">
          <div class="d-flex flex-row-reverse mt-3 mb-2 me-1">
            <div class="justify-content-center">
              <small class="text-muted"
                >Jumlah pengguna: {{ findNamePektaBaru().length }}</small
              >
            </div>
          </div>
          <table class="table">
            <thead>
              <tr>
                <th style="width: 30px">#</th>
                <th style="width: 300px">Pemohon</th>
                <th style="width: 100px">Daerah</th>
                <th style="width: 100px">Negeri</th>
                <th style="width: 100px">Tahun Lantikan</th>
                <th style="width: 100px">Jantina</th>
                <!-- <th style="width: 300px">Kelayakan</th> -->
                <th style="width: 100px">Status</th>
              </tr>
            </thead>
            <tbody class="border-0">
              <tr
                class="border-light"
                v-for="(pekta, idx) in findNamePektaBaru()"
                :key="idx"
              >
                <td>{{ idx + 1 }}</td>
                <td>
                  {{ pekta.full_name ? pekta.full_name : "-" }}
                </td>
                <td>
                  {{ pekta.district ? pekta.district : "-" }}
                </td>
                <td>
                  {{ pekta.state ? pekta.state : "-" }}
                </td>
                <td scope="row">
                  {{
                      pekta.registration_number
                        ? pekta.registration_number.substring(0, 4)
                        : "-"
                  }}
                </td>
                <td>
                  {{ pekta.gender ? pekta.gender : "-" }}
                </td>
                <!-- <td>
                  <div>
                    <div
                      class="d-flex"
                      style="font-size: 0.8rem"
                      v-for="(qualification, i) in qualifications(pekta)"
                      :key="i"
                    >
                      <p class="mb-0 me-1">
                        {{
                          qualification.level
                            ? helper.snakeToTitleCase(qualification.level)
                            : ""
                        }}
                      </p>
                      <p class="fw-bold">
                        {{
                          qualification.type
                            ? `(${helper.snakeToTitleCase(qualification.type)})`
                            : ""
                        }}
                      </p>
                    </div>
                  </div>
                </td> -->
                <td>
                  <div
                    :class="[
                      'badge',
                      'rounded-pill',
                      getStatus(pekta) === 'Diluluskan' ? 'bg-success' : 
                        getStatus(pekta) === 'Menunggu Tindakan' ? 'bg-warning' : 
                        getStatus(pekta) === 'Titolak' ? 'bg-danger' : 
                        getStatus(pekta) === 'Dalam Proses' ? 'bg-warning' : '',
                      'text-white'
                    ]"
                  >
                    {{getStatus(pekta)}}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import qs from "qs";
import API from "../../../utils/API";
import overviewChart from "../Chart/overviewChart.vue";
import Helper from "../../../utils/helper";
import * as XLSX from "xlsx";
import printJS from "print-js";
import moment from "moment";

export default {
  name: "SenaraiPekta",
  components: {
    overviewChart,
  },
  data() {
    return {
      helper: Helper,
      searchingDiDafter: "",
      searchingBaru: "",
      activeTabSenarai: "Baru", // Now Baru == Diluluskan, Berdaftar == Ditolak.
      chartDataDiDaftar: null,
      chartDataBaru: null,
      pektasDiDaftar: [],
      pektasBaru: [],
      states: [
        "Johor",
        "Kedah",
        "Kelantan",
        "Melaka",
        "Negeri Sembilan",
        "Pahang",
        "Perak",
        "Perlis",
        "Pulau Pinang",
        "Sarawak",
        "Selangor",
        "Terengganu",
        "Kuala Lumpur",
        "Labuan",
        "Sabah",
        "Putrajaya",
      ],
      filterOption: {
        state: null,
        gender: null,
        age: {
          min: null,
          max: null,
        },
        status: null,
        year: null
      },
      katamStatusList: [
        { value: "approved", name: "Diluluskan" },
        { value: "processing", name: "Dalam Proses" },
        { value: "draft", name: "Draf" },
        { value: "rejected", name: "Ditolak" },
        { value: "pending", name: "Menunggu Tindakan" },
        { value: "interview", name: "Panggilan Temuduga" },
      ],
      pektaSetting: null
    };
  },
  watch: {
    activeTabSenarai: {
      handler() {
        this.chartDataBaru = null;
        this.chartDataDiDaftar = null;
        this.pektasDiDaftar = [];
        this.pektasBaru = [];
        this.getPekta();

        this.filterOption.state = null;
        this.filterOption.gender = null;
        this.filterOption.status = null;
        this.filterOption.year = null;
        this.filterOption.age.min = null;
        this.filterOption.age.max = null;
      },
    },
    // filterOption: {
    //   handler(_val){
    //     console.log('val :', _val)
    //   },
    //   deep: true
    // }
  },
  methods: {
    init(){
      return new Promise(async (resolve, reject) => {
        const res = await API.get('pekta-setting')
        this.pektaSetting = res.data.data.attributes

        resolve(0)
      })
    },
    handleDownload(_type) {
      const newData =
        _type == "Berdaftar"
          ? this.findNamePektaDiDaftar()
          : this.findNamePektaBaru();
      let headers = [
        "#",
        "Nama",
        "No. PEKTA",
        "Daerah",
        "Negeri",
        "Umur",
        "Jantina",
        // "Kelayakan",
        "Status",
      ];

      let rowData = newData.map((userPekta, index) => {
        let kelayakan = "";
        let status = "";
        // this.qualifications(pekta).forEach((qualification) => {
        //   kelayakan = qualification.level
        //     ? this.helper.snakeToTitleCase(qualification.level)
        //     : "";
        //   status = qualification.type
        //     ? `(${this.helper.snakeToTitleCase(qualification.type)})`
        //     : "";
        // });
        return [
          index + 1,
          userPekta.full_name ? userPekta.full_name : "-",
          userPekta.registration_number ? userPekta.registration_number : "-",
          userPekta.district ? userPekta.district : "-",
          userPekta.state ? userPekta.state : "-",
          userPekta.age ? userPekta.age : "-",
          userPekta.gender ? userPekta.gender : "-",
          // kelayakan + status,
          this.getStatus(userPekta)
        ];
      });
      let data = [headers, ...rowData];
      const ws = XLSX.utils.aoa_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
      /* generate file and send to client */
      XLSX.writeFile(wb, "sheetjs.xlsx");
    },
    handlePrint(_type) {
      const newData =
        _type == "Berdaftar"
          ? this.findNamePektaDiDaftar()
          : this.findNamePektaBaru();
      printJS({
        printable: newData.map((pekta, index) => {
          let kelayakan = "";
          let status = "";
          this.qualifications(pekta).forEach((qualification) => {
            kelayakan = qualification.level
              ? this.helper.snakeToTitleCase(qualification.level)
              : "";
            status = qualification.type
              ? `(${this.helper.snakeToTitleCase(qualification.type)})`
              : "";
          });
          console.log(status);
          return {
            index: index + 1,
            nama: pekta.attributes.fullname ? pekta.attributes.fullname : "-",
            daerah:
              pekta.attributes.user.data &&
              pekta.attributes.user.data.attributes.district
                ? pekta.attributes.user.data.attributes.district
                : "-",
            negeri:
              pekta.attributes.user.data &&
              pekta.attributes.user.data.attributes.state
                ? pekta.attributes.user.data.attributes.state
                : "-",
            umur:
              pekta.attributes.user.data &&
              pekta.attributes.user.data.attributes.age
                ? pekta.attributes.user.data.attributes.age
                : "-",
            jantina:
              pekta.attributes.user.data &&
              pekta.attributes.user.data.attributes.gender
                ? pekta.attributes.user.data.attributes.gender
                : "-",
            kelayakan: kelayakan + status,
            status: "Active",
          };
        }),
        properties: [
          { field: "index", displayName: "#" },
          { field: "nama", displayName: "Nama" },
          { field: "daerah", displayName: "Daerah" },
          { field: "negeri", displayName: "Negeri" },
          { field: "umur", displayName: "Umur" },
          { field: "jantina", displayName: "Jantina" },
          { field: "kelayakan", displayName: "Kelayakan" },
          { field: "status", displayName: "Status" },
        ],
        type: "json",
      });
    },
    findNamePektaBaru() {
      // return this.searchingBaru.length > 0
      //   ? this.pektasBaru.filter((x) => {
      //       if (x.attributes.fullname != null)
      //         return (
      //           x.attributes.fullname != null &&
      //           x.attributes.fullname.toLowerCase().includes(this.searchingBaru)
      //         );
      //       else return null;
      //     })
      //   : this.pektasBaru;
      let finalPektasBaru = this.pektasBaru;

      if (this.searchingBaru.length > 0) {
        finalPektasBaru = finalPektasBaru.filter((x) => {
          if (x.full_name != null)
            return x.full_name.toLowerCase().includes(this.searchingBaru.toLowerCase());
          else return null;
        });
      }

      if (this.filterOption.state) {
        finalPektasBaru = finalPektasBaru.filter((x) => {
          if (x.state != null)
            return x.state
              .toLowerCase()
              .includes(this.filterOption.state.toLowerCase());
        });
      }

      if (this.filterOption.gender) {
        finalPektasBaru = finalPektasBaru.filter((x) => {
          if (x.gender != null)
            return x.gender
              .toLowerCase()
              .includes(this.filterOption.gender.toLowerCase());
        });
      }

      if (this.filterOption.age.min && this.filterOption.age.max) {
        finalPektasBaru = finalPektasBaru.filter((x) => {
          if (x.age != null)
            return (
              x.age >= this.filterOption.age.min &&
              x.age <= this.filterOption.age.max
            );
        });
      }

      if (this.filterOption.year) {
        finalPektasBaru = finalPektasBaru.filter((x) => {
            if (x.registration_number != null)
              return x.registration_number
                .substring(0, 4)
                .includes(this.filterOption.year);
          });
      }

      if (this.filterOption.status) {
        finalPektasBaru = finalPektasBaru.filter((x) => {
          const latestKatamApplication = x.pekta.katam_applications
          .slice()
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[0];

            return latestKatamApplication.status.includes(this.filterOption.status);
          });
      }

      return finalPektasBaru;
    },
    findNamePektaDiDaftar() {
      // return this.searchingDiDafter.length > 0
      //   ? this.pektasDiDaftar.filter((x) => {
      //       if (x.attributes.fullname != null)
      //         return x.attributes.fullname
      //           .toLowerCase()
      //           .includes(this.searchingDiDafter);
      //       else return null;
      //     })
      //   : this.pektasDiDaftar;
      let finalPektasDiDaftar = this.pektasDiDaftar;

      if (this.searchingDiDafter.length > 0) {
        finalPektasDiDaftar = finalPektasDiDaftar.filter((x) => {
          if (x.full_name != null)
            return x.full_name.toLowerCase().includes(this.searchingDiDafter.toLowerCase());
          else return null;
        });
      }

      if (this.filterOption.state) {
        finalPektasDiDaftar = finalPektasDiDaftar.filter((x) => {
          if (x.state != null)
            return x.state
              .toLowerCase()
              .includes(this.filterOption.state.toLowerCase());
        });
      }

      if (this.filterOption.gender) {
        finalPektasDiDaftar = finalPektasDiDaftar.filter((x) => {
          if (x.gender != null)
            return x.gender
              .toLowerCase()
              .includes(this.filterOption.gender.toLowerCase());
        });
      }

      if (this.filterOption.age.min && this.filterOption.age.max) {
        finalPektasDiDaftar = finalPektasDiDaftar.filter((x) => {
          if (x.age != null)
            return (
              x.age >= this.filterOption.age.min &&
              x.age <= this.filterOption.age.max
            );
        });
      }

      if (this.filterOption.year) {
        finalPektasDiDaftar = finalPektasDiDaftar.filter((x) => {
            if (x.registration_number != null)
              return x.registration_number
                .substring(0, 4)
                .includes(this.filterOption.year);
          });
      }

      if (this.filterOption.status) {
        finalPektasDiDaftar = finalPektasDiDaftar.filter((x) => {
          const latestKatamApplication = x.pekta.katam_applications
          .slice()
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[0];

            return latestKatamApplication.status.includes(this.filterOption.status);
          });
      }

      return finalPektasDiDaftar;
    },
    // qualifications(item) {
    //   let newItem = [];
    //   if (
    //     item.attributes.pekta_applications.data &&
    //     item.attributes.pekta_applications.data.length > 0
    //   ) {
    //     if (
    //       item.attributes.pekta_applications.data[0].attributes &&
    //       item.attributes.pekta_applications.data[0].attributes
    //         .pekta_qualification &&
    //       item.attributes.pekta_applications.data[0].attributes
    //         .pekta_qualification.length > 0
    //     ) {
    //       newItem =
    //         item.attributes.pekta_applications.data[0].attributes
    //           .pekta_qualification;
    //     }
    //   }
    //   return newItem;
    // },
    async getPekta() {
      // const berdaftarQuery = qs.stringify(
      //   {
      //     // limit: 20,
      //     filters: {
      //       role: {
      //         id: {
      //           $eq: "4",
      //         },
      //       },
      //       pekta: {
      //         katam_applications: {
      //           status: {
      //             $in: ["pending", "draft", "processing", "rejected", "approved"],
      //           },
      //         },
      //       },
      //       createdAt: {
      //         $gte: moment([2000]).startOf("year").format("YYYY-MM-DD"),
      //         $lte: moment([moment().year() - 1])
      //           .endOf("year")
      //           .format("YYYY-MM-DD"),
      //       },
      //     },
      //     populate: ["role", "pekta", "pekta.katam_applications"],
      //   },
      //   {
      //     encodeValuesOnly: true,
      //   }
      // );
      const berdaftarQuery = qs.stringify(
        {
          // limit: 20,
          filters: {
            role: {
              id: {
                $eq: "4",
              },
            },
            pekta: {
              katam_applications: {
                status: {
                  $in: ["approved"],
                },
              },
            },
            createdAt: {
                $lte: moment([moment().year() - 1])
                  .endOf("year")
                  .format("YYYY-MM-DD"),
            },
          },
          populate: ["role", "pekta", "pekta.katam_applications"],
        },
        {
          encodeValuesOnly: true,
        }
      );
      const resBerdaftar = await API.get(`users?${berdaftarQuery}`);

      // const baharuQuery = qs.stringify(
      //   {
      //     // limit: 20,
      //     filters: {
      //       role: {
      //         id: {
      //           $eq: "4",
      //         },
      //       },
      //       pekta: {
      //         katam_applications: {
      //           status: {
      //             $in: ["pending", "draft", "processing", "rejected", "approved"],
      //           },
      //         },
      //       },
      //       createdAt: {
      //           $gte: moment().startOf("year").format("YYYY-MM-DD"),
      //           $lte: moment().endOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      //       },
      //     },
      //     populate: ["role", "pekta", "pekta.katam_applications"],
      //   },
      //   {
      //     encodeValuesOnly: true,
      //   }
      // );
      const baharuQuery = qs.stringify(
        {
          // limit: 20,
          filters: {
            role: {
              id: {
                $eq: "4",
              },
            },
            pekta: {
              katam_applications: {
                status: {
                  $in: ["approved"],
                },
                createdAt: {
                  // $gte: moment().startOf("year").format("YYYY-MM-DD"),
                  $gte: this.pektaSetting.katam_expiry_date,
                  $lte: moment().endOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                },
              },
            },
          },
          populate: ["role", "pekta", "pekta.katam_applications"],
        },
        {
          encodeValuesOnly: true,
        }
      );
      const resBaharu = await API.get(`users?${baharuQuery}`);

      // console.log('resBaharu : ', resBaharu.data)

      //only data diluluskan
      this.pektasDiDaftar = resBerdaftar.data.map(i => {
        const approvedApplications = i.pekta.katam_applications.filter(app => app.status === "approved");

        return {
          ...i,
          pekta: {
            ...i.pekta, 
            katam_applications: approvedApplications,
          }
        };
      });

      this.pektasBaru = resBaharu.data;

      // console.log('this.pektasDiDaftar : ', this.pektasDiDaftar)
      // console.log('this.pektasBaru : ', this.pektasBaru)

      let newItemDidaftar = this.pektasDiDaftar
        .filter((x) => {
          if (x.state) {
            return this.helper.snakeToTitleCase(x.state);
          }
        })
        .map((x) => {
          return this.helper.snakeToTitleCase(x.state);
        });

      let newItemBaru = this.pektasBaru
        .filter((x) => {
          if (x.state) {
            return this.helper.snakeToTitleCase(x.state);
          }
        })
        .map((x) => {
          return this.helper.snakeToTitleCase(x.state);
        });

      let senaraiDiDaftar = [
        {
          label: "Perlis",
          value: newItemDidaftar.filter((str) => {
            return str.toLowerCase().indexOf("perlis".toLowerCase()) >= 0;
          }).length,
        },
        {
          label: "Kedah",
          value: newItemDidaftar.filter((str) => {
            return str.toLowerCase().indexOf("kedah".toLowerCase()) >= 0;
          }).length,
        },
        {
          label: "Perak",
          value: newItemDidaftar.filter((str) => {
            return str.toLowerCase().indexOf("perak".toLowerCase()) >= 0;
          }).length,
        },
        {
          label: "Selangor",
          value: newItemDidaftar.filter((str) => {
            return str.toLowerCase().indexOf("selangor".toLowerCase()) >= 0;
          }).length,
        },
        {
          label: "Kuala Lumpur",
          value: newItemDidaftar.filter((str) => {
            return str.toLowerCase().indexOf("kuala lumpur".toLowerCase()) >= 0;
          }).length,
        },
        {
          label: "Melaka",
          value: newItemDidaftar.filter((str) => {
            return str.toLowerCase().indexOf("melaka".toLowerCase()) >= 0;
          }).length,
        },
        {
          label: "Negeri Sembilan",
          value: newItemDidaftar.filter((str) => {
            return (
              str.toLowerCase().indexOf("negeri sembilan".toLowerCase()) >= 0
            );
          }).length,
        },
        {
          label: "Johor",
          value: newItemDidaftar.filter((str) => {
            return str.toLowerCase().indexOf("johor".toLowerCase()) >= 0;
          }).length,
        },
        {
          label: "Pahang",
          value: newItemDidaftar.filter((str) => {
            return str.toLowerCase().indexOf("pahang".toLowerCase()) >= 0;
          }).length,
        },
        {
          label: "Terengganu",
          value: newItemDidaftar.filter((str) => {
            return str.toLowerCase().indexOf("terengganu".toLowerCase()) >= 0;
          }).length,
        },
        {
          label: "Kelantan",
          value: newItemDidaftar.filter((str) => {
            return str.toLowerCase().indexOf("kelantan".toLowerCase()) >= 0;
          }).length,
        },
        {
          label: "Sarawak",
          value: newItemDidaftar.filter((str) => {
            return str.toLowerCase().indexOf("sarawak".toLowerCase()) >= 0;
          }).length,
        },
        {
          label: "Sabah",
          value: newItemDidaftar.filter((str) => {
            return str.toLowerCase().indexOf("sabah".toLowerCase()) >= 0;
          }).length,
        },
      ];

      let senaraiBaru = [
        {
          label: "Perlis",
          value: newItemBaru.filter((str) => {
            return str.toLowerCase().indexOf("perlis".toLowerCase()) >= 0;
          }).length,
        },
        {
          label: "Kedah",
          value: newItemBaru.filter((str) => {
            return str.toLowerCase().indexOf("kedah".toLowerCase()) >= 0;
          }).length,
        },
        {
          label: "Perak",
          value: newItemBaru.filter((str) => {
            return str.toLowerCase().indexOf("perak".toLowerCase()) >= 0;
          }).length,
        },
        {
          label: "Selangor",
          value: newItemBaru.filter((str) => {
            return str.toLowerCase().indexOf("selangor".toLowerCase()) >= 0;
          }).length,
        },
        {
          label: "W.Persekutuan",
          value: newItemBaru.filter((str) => {
            return str.toLowerCase().indexOf("kuala lumpur".toLowerCase()) >= 0;
          }).length,
        },
        {
          label: "Melaka",
          value: newItemBaru.filter((str) => {
            return str.toLowerCase().indexOf("melaka".toLowerCase()) >= 0;
          }).length,
        },
        {
          label: "Negeri Sembilan",
          value: newItemBaru.filter((str) => {
            return (
              str.toLowerCase().indexOf("negeri sembilan".toLowerCase()) >= 0
            );
          }).length,
        },
        {
          label: "Johor",
          value: newItemBaru.filter((str) => {
            return str.toLowerCase().indexOf("johor".toLowerCase()) >= 0;
          }).length,
        },
        {
          label: "Pahang",
          value: newItemBaru.filter((str) => {
            return str.toLowerCase().indexOf("pahang".toLowerCase()) >= 0;
          }).length,
        },
        {
          label: "Terengganu",
          value: newItemBaru.filter((str) => {
            return str.toLowerCase().indexOf("terengganu".toLowerCase()) >= 0;
          }).length,
        },
        {
          label: "Kelantan",
          value: newItemBaru.filter((str) => {
            return str.toLowerCase().indexOf("kelantan".toLowerCase()) >= 0;
          }).length,
        },
        {
          label: "Sarawak",
          value: newItemBaru.filter((str) => {
            return str.toLowerCase().indexOf("sarawak".toLowerCase()) >= 0;
          }).length,
        },
        {
          label: "Sabah",
          value: newItemBaru.filter((str) => {
            return str.toLowerCase().indexOf("sabah".toLowerCase()) >= 0;
          }).length,
        },
      ];

      this.chartDataDiDaftar = {
        labels: senaraiDiDaftar.map((x) => {
          return x.label;
        }),
        data: senaraiDiDaftar.map((x) => {
          return x.value;
        }),
      };
      this.chartDataBaru = {
        labels: senaraiBaru.map((x) => {
          return x.label;
        }),
        data: senaraiBaru.map((x) => {
          return x.value;
        }),
      };
    },
    getStatus(user){
      const latestKatamApplication = user.pekta.katam_applications
        .slice()
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[0];

      const katamStatus = this.katamStatusList.filter((o) => {
          return o.value == latestKatamApplication.status;
        });
        return katamStatus[0].name;
    },
    generateYears(startYear, endYear) {
      const years = [];
      for (let year = startYear; year <= endYear; year++) {
        years.push(year.toString());
      }
      return years;
    },
  },
  async mounted() {
    await this.init()
    this.getPekta();
  },
};
</script>

<style scoped>
.body-container {
  min-height: calc(100vh - 170px);
}

.list-group-item {
  cursor: pointer;
}

.inactive {
  color: #9aa0ad;
}

.is-active {
  color: #4ca022;
}

.card-custom {
  position: relative;
  display: block;
  height: 40px;
}

.img-container {
  position: inherit;
  display: block;
  height: 100%;
  width: 100%;
  background-image: url("../../../assets/img/file-icon.svg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
}

.col-3.col-custom {
  min-width: 250px !important;
}

.input-custom {
  width: 200px;
}

.tableFixHead {
  overflow: auto;
  min-height: 200px;
  max-height: 500px;
}
.tableFixHead thead th {
  position: sticky;
  top: 0;
  z-index: 1;
}

/* Just common table stuff. Really. */
table {
  border-collapse: collapse;
  width: 100%;
}
th,
td {
  padding: 8px 16px;
}
th {
  background: #f9fafb;
}

::-webkit-scrollbar {
  display: none !important;
}
</style>
