<template>
    <div>
        <div v-if="!isLoading" id="downloadable">
            <div class="d-flex justify-content-between" ref="sectionContent">
                <div class="d-flex">
                    <p class="me-2 mb-0 fs-6 align-items-center mt-2">Pilih Kursus:</p>
                    <select
                        class="form-select"
                        aria-label="Default select example"
                        style="width: 240px; margin-right: 2px;"
                        v-model="selectedCourse"
                    >
                        <option
                        v-for="course in courses"
                        :key="course.id"
                        :value="course"
                        >
                        {{ course.attributes.course_name }}
                        </option>
                    </select>
                    <button
                        type="button"
                        class="btn btn-sm border ms-2"
                        @click="handleDownload()"
                        :disabled="isSaving"
                    >
                        <i class="fas fa-file-download me-2" />
                        {{ isSaving ? 'Menunggu...' : 'Muat Turun' }}
                    </button>
                </div>
                <div class="d-flex">
                    <p class="me-2 mb-0 fs-6 align-items-center mt-2">Paparan:</p>
                    <select
                        class="form-select"
                        aria-label="Default select example"
                        style="width: 150px; margin-right: 2px;"
                        v-model="selectedView"
                    >
                        <option value="all">Semua</option>
                        <option value="Jantina">Jantina</option>
                        <option value="Umur">Umur</option>
                        <option value="Negeri">Negeri</option>
                        <option value="Pekerjaan">Pekerjaan</option>
                        <option value="TahapPendidikan">Tahap Pendidikan</option>
                        <option value="BahagianB">Bahagian B</option>
                    </select>   
                </div>
            </div>
            <div class="d-flex align-items-center mt-4">
                <p class="me-2 mb-0 fs-6">Pilih Pengguna:</p>
                <select
                    class="form-select"
                    aria-label="Default select example"
                    style="width: 240px; margin-right: 2px;"
                    v-model="selectedRole"
                >
                    <option value="all">Semua</option>
                    <option value="BH">Bakal Haji</option>
                    <option value="Pekta">PEKTA</option>
                </select>   
            </div>
            <div>
                <div class="row" :style="{ 'margin-top': selectedView === 'all' ? '2rem' : '0' }">
                    <div v-if="enableView.jantina == true" class="col-4">
                        <h5 class="mb-3">Jantina</h5>
                        <custom-chart 
                            id="surveyChart01" 
                            :dataSource="genderChartData" 
                            type="pie"
                            :hasOption="false"
                        />
                    </div>
                    <div v-if="enableView.umur == true" class="col-8">
                        <h5 class="mb-3">Umur</h5>
                        <custom-chart 
                            id="surveyChart02" 
                            :dataSource="ageChartData" 
                            type="bar"
                            :hasOption="true"
                        />
                    </div>
                </div>
            </div>
            <div>
                <div class="row" :style="{ 'margin-top': selectedView === 'all' ? '2rem' : '0' }">
                    <div v-if="enableView.negeri  == true" class="col-12">
                        <h5 class="mb-3">Negeri</h5>
                        <custom-chart 
                            id="surveyChart03" 
                            :dataSource="stateChartData" 
                            type="bar"
                            :hasOption="true"
                        />
                    </div>
                </div>
            </div>
            <div>
                <div class="row" :style="{ 'margin-top': selectedView === 'all' ? '2rem' : '0' }">
                    <div v-if="enableView.pekerjaan == true" class="col-12">
                        <h5 class="mb-3">Pekerjaan</h5>
                        <custom-chart 
                            id="surveyChart04" 
                            :dataSource="occupationChartData" 
                            type="bar"
                            :hasOption="true"
                        />
                    </div>
                </div>
            </div>
            <div class="mt-5">
                <div class="row">
                    <div v-if="enableView.tahapPendidikan == true" class="col-12">
                        <h5 class="mb-3">Tahap Pendidikan</h5>
                        <custom-chart 
                            id="surveyChart05" 
                            :dataSource="edulevelChartData" 
                            type="bar"
                            :hasOption="true"
                        />
                    </div>
                </div>
            </div>

            <div v-if="enableView.bahagianB == true" :style="{ 'margin-top': selectedView === 'all' ? '2rem' : '0' }">
                <h5 class="mb-3">Bahagian B</h5>
                <div v-for="(data, ind) in sectionBData" :key="data.id" class="mb-3">
                    <div class="row">
                        <div class="col-4">
                            <span>{{ ind +1 }}.</span>
                            <span>{{ data.question }}</span>
                        </div>
                        <div class="col-8">
                            <div class="row">
                                <div class="col">
                                    <div class="card scale-card" style="background-color: green; color: white;">
                                        <div class="card-body text-center">
                                            <span class="d-block">{{ data.very_high }}</span>
                                            <span class="d-block">Sangat Tinggi</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="card scale-card" style="background-color: #8BC34A; color: white;">
                                        <div class="card-body text-center">
                                            <span class="d-block">{{ data.high }}</span>
                                            <span class="d-block">Tinggi</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="card scale-card" style="background-color: #c8b934; color: white;">
                                        <div class="card-body text-center">
                                            <span class="d-block">{{ data.medium }}</span>
                                            <span class="d-block">Sederhana</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="card scale-card" style="background-color: #FF9800; color: white;">
                                        <div class="card-body text-center">
                                            <span class="d-block">{{ data.low }}</span>
                                            <span class="d-block">Rendah</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="card scale-card" style="background-color: #F44336; color: white;">
                                        <div class="card-body text-center">
                                            <span class="d-block">{{ data.very_low }}</span>
                                            <span class="d-block">Sangat Rendah</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            Sila tunggu. Memuatkan data...
        </div>
    </div>
</template>

<script>
import API from "./../../utils/API";
import asyncEachSeries from 'async/eachSeries'
import * as XLSX from "xlsx";

import customChart from "./../../views/admin/Chart/customChart.vue";

export default {
    props: ['roleAccess'],
    components: {
        customChart,
    },
    data(){
        return {
            isLoading: false,
            isSaving: false,
            enableView: {
                jantina: true,
                umur: true,
                negeri: true,
                pekerjaan: true,
                tahapPendidikan: true,
                bahagianB: true,
            },
            courses: [],
            selectedCourse: null,
            selectedView: null,
            surveyQuestions: [],
            surveyAnswers: [],
            genderChartData: {
                labels: [],
                data: [],
            },
            ageChartData: {
                labels: [],
                data: [],
            },
            stateChartData: {
                labels: [],
                data: [],
            },
            occupationChartData: {
                labels: [],
                data: [],
            },
            edulevelChartData: {
                labels: [],
                data: [],
            },
            selectedRole: null,
            roleList: [
                { value: "BH", name: "Bakal Haji" },
                { value: "Pekta", name: "PEKTA" },
                { value: "Petugas", name: "Petugas Haji" },
            ],
            sectionBData: [],
            userIds: [],
        }
    },
    watch: {
        selectedCourse: {
            async handler() {
                // console.log(this.selectedCourse);
                this.isLoading = true
                this.genderChartData = {
                    labels: [],
                    data: [],
                }
                this.ageChartData = {
                    labels: [],
                    data: [],
                }
                this.stateChartData = {
                    labels: [],
                    data: [],
                }
                this.occupationChartData = {
                    labels: [],
                    data: [],
                }
                this.edulevelChartData = {
                    labels: [],
                    data: [],
                }

                this.sectionBData = []

                await this.initAllData()
                this.isLoading = false
            },
        },
        selectedView: {
            async handler(){
                this.isLoading = true;
                this.genderChartData = {
                    labels: [],
                    data: [],
                }
                this.ageChartData = {
                    labels: [],
                    data: [],
                }
                this.stateChartData = {
                    labels: [],
                    data: [],
                }
                this.occupationChartData = {
                    labels: [],
                    data: [],
                }
                this.edulevelChartData = {
                    labels: [],
                    data: [],
                }

                this.sectionBData = [];
                await this.initAllData();
                this.isLoading = false;
            }
        },
        selectedRole: {
            async handler() {
                // console.log(this.selectedCourse);
                this.isLoading = true
                this.genderChartData = {
                    labels: [],
                    data: [],
                }
                this.ageChartData = {
                    labels: [],
                    data: [],
                }
                this.stateChartData = {
                    labels: [],
                    data: [],
                }
                this.occupationChartData = {
                    labels: [],
                    data: [],
                }
                this.edulevelChartData = {
                    labels: [],
                    data: [],
                }

                this.sectionBData = []

                await this.initAllData()
                this.isLoading = false
            },
        },
    },
    methods: {
        initAllData(){
            return new Promise(async (resolve, reject) => {
                const res02 = await API.get('survey-questions?populate=%2A')
                let res03;

                switch (this.selectedRole) {
                    case 'all':
                        res03 = await API.get(`answered-surveys?populate=course&populate=survey_question_answer&populate=users_permissions_user&filters[course][id][$eq]=${this.selectedCourse.id}`);
                        break;
                    case 'Pekta':
                        res03 = await API.get(`answered-surveys?populate=course&populate=survey_question_answer&populate=users_permissions_user&populate=users_permissions_user.pekta&filters[users_permissions_user][id][$gte]=1&filters[users_permissions_user][pekta][id][$gte]=1&filters[course][id][$eq]=${this.selectedCourse.id}`);
                        break;
                    case 'BH':
                        res03 = await API.get(`answered-surveys?populate=course&populate=survey_question_answer&populate=users_permissions_user&populate=users_permissions_user.pekta&filters[users_permissions_user][id][$gte]=1&filters[users_permissions_user][pekta][id][$null]=true&filters[course][id][$eq]=${this.selectedCourse.id}`);
                }

                this.surveyQuestions = res02.data.data
                this.surveyAnswers = res03.data.data

                switch (this.selectedView){

                    case "all":
                        this.enableView.jantina = true;
                        this.enableView.umur = true;
                        this.enableView.negeri = true;
                        this.enableView.pekerjaan = true;
                        this.enableView.tahapPendidikan = true;
                        this.enableView.bahagianB = true;
                        break;
                    case "Jantina":
                        this.enableView.jantina = true;
                        this.enableView.umur = false;
                        this.enableView.negeri = false;
                        this.enableView.pekerjaan = false;
                        this.enableView.tahapPendidikan = false;
                        this.enableView.bahagianB = false;
                        break;
                    case "Umur":
                        this.enableView.jantina = false;
                        this.enableView.umur = true;
                        this.enableView.negeri = false;
                        this.enableView.pekerjaan = false;
                        this.enableView.tahapPendidikan = false;
                        this.enableView.bahagianB = false;
                        break;
                    case "Negeri":
                        this.enableView.jantina = false;
                        this.enableView.umur = false;
                        this.enableView.negeri = true;
                        this.enableView.pekerjaan = false;
                        this.enableView.tahapPendidikan = false;
                        this.enableView.bahagianB = false;
                        break;
                    case "Pekerjaan":
                        this.enableView.jantina = false;
                        this.enableView.umur = false;
                        this.enableView.negeri = false;
                        this.enableView.pekerjaan = true;
                        this.enableView.tahapPendidikan = false;
                        this.enableView.bahagianB = false;
                        break;
                    case "TahapPendidikan":
                        this.enableView.jantina = false;
                        this.enableView.umur = false;
                        this.enableView.negeri = false;
                        this.enableView.pekerjaan = false;
                        this.enableView.tahapPendidikan = true;
                        this.enableView.bahagianB = false;
                        break;
                    case "TahapPendidikan":
                        this.enableView.jantina = false;
                        this.enableView.umur = false;
                        this.enableView.negeri = false;
                        this.enableView.pekerjaan = false;
                        this.enableView.tahapPendidikan = true;
                        this.enableView.bahagianB = false;
                        break;
                    case "BahagianB":
                        this.enableView.jantina = false;
                        this.enableView.umur = false;
                        this.enableView.negeri = false;
                        this.enableView.pekerjaan = false;
                        this.enableView.tahapPendidikan = false;
                        this.enableView.bahagianB = true;
                        break;
                }

                await this.initSurveyChartLabels()
                await this.initSurveyChartData()
                await this.initSectionBData()

                resolve()
            })
        },
        initSurveyChartLabels() {
            return new Promise(async (resolve, reject) => {
                asyncEachSeries(this.surveyQuestions, (item, cb) => { //arrange labels for chart
                    if (item.attributes.section == 'a') {
                        switch (item.attributes.question) {
                            case 'Jantina':
                                item.attributes.answer.forEach(i => this.genderChartData.labels.push(i.text))
                                this.genderChartData.data = new Array(this.genderChartData.labels.length).fill(0)
                                break;
                            case 'Umur':
                                item.attributes.answer.forEach(i => this.ageChartData.labels.push(i.text))
                                this.ageChartData.data = new Array(this.ageChartData.labels.length).fill(0)
                                break;
                            case 'Negeri':
                                item.attributes.answer.forEach(i => this.stateChartData.labels.push(i.text))
                                this.stateChartData.data = new Array(this.stateChartData.labels.length).fill(0)
                                break;
                            case 'Jenis Pekerjaan':
                                item.attributes.answer.forEach(i => this.occupationChartData.labels.push(i.text))
                                this.occupationChartData.data = new Array(this.occupationChartData.labels.length).fill(0)
                                break;
                            case 'Tahap Pendidikan':
                                item.attributes.answer.forEach(i => this.edulevelChartData.labels.push(i.text))
                                this.edulevelChartData.data = new Array(this.edulevelChartData.labels.length).fill(0)
                                break;
                        }
                    }

                    cb()
                }, (err) => {
                    if(err) reject(err)
                    resolve()
                })
            })
        },
        initSurveyChartData() {
            return new Promise(async (resolve, reject) => {
                asyncEachSeries(this.surveyAnswers, (item, cb) => { //arrange data for chart
                    item.attributes.survey_question_answer.forEach(subItem => {
                        let indFound
                        switch (subItem.question) {
                            case 'Jantina':
                                indFound = this.genderChartData.labels.findIndex(i => i == subItem.answer)
                                if (indFound != -1) this.genderChartData.data[indFound]++
                                break;
                            case 'Umur':
                                indFound = this.ageChartData.labels.findIndex(i => i == subItem.answer)
                                if (indFound != -1) this.ageChartData.data[indFound]++
                                break;
                            case 'Negeri':
                                indFound = this.stateChartData.labels.findIndex(i => i == subItem.answer)
                                if (indFound != -1) this.stateChartData.data[indFound]++
                                break;
                            case 'Jenis Pekerjaan':
                                indFound = this.occupationChartData.labels.findIndex(i => i == subItem.answer)
                                if (indFound != -1) this.occupationChartData.data[indFound]++
                                break;
                            case 'Tahap Pendidikan':
                                indFound = this.edulevelChartData.labels.findIndex(i => i == subItem.answer)
                                if (indFound != -1) this.edulevelChartData.data[indFound]++
                                break;
                        }
                    })

                    cb()
                }, (err) => {
                    if (err) reject(err)
                    resolve()
                })
            })
        },
        initSectionBData() {
            return new Promise(async (resolve, reject) => {
                asyncEachSeries(this.surveyQuestions, (item, cb) => {

                    if (item.attributes.section == 'b') {
                        let scaleObj = {
                            question: '',
                            very_low: 0,
                            low: 0,
                            medium: 0,
                            high: 0,
                            very_high: 0
                        }
                        
                        scaleObj.question = item.attributes.question
                        let foundItem
                        this.surveyAnswers.forEach(subItem => {
                            foundItem = subItem.attributes.survey_question_answer.find(i => i.question == item.attributes.question)

                            if(foundItem){

                                switch (foundItem.answer) {
                                    case 'Sangat Rendah':
                                        scaleObj.very_low++
                                        break;
                                    case 'Rendah':
                                        scaleObj.low++
                                        break;
                                    case 'Sederhana':
                                        scaleObj.medium++
                                        break;
                                    case 'Tinggi':
                                        scaleObj.high++
                                        break;
                                    case 'Sangat Tinggi':
                                        scaleObj.very_high++
                                        break;
                                }
                            }
                        })

                        this.sectionBData.push(scaleObj)
                    }
    
                    cb()
                }, (err) => {
                    if(err) reject(err)
                    resolve()
                })
            })
        },
        handleDownload(){
            const headers = [
                "Kategori Pengguna",
                "#",
                "No. Pendaftaran",
                "Emel",
                "Jantina",
                "Umur",
                "Negeri",
                "Jenis Pekerjaan",
                "Tahap Pendidikan",
                "Apakah tahap pengetahuan anda selepas mengikuti kursus?",
                "Adakah kursus ini berjaya meningkatkan pengetahuan anda dalam ibadat Haji?",
                "Adakah objektif pembelajaran dinyatakan dengan jelas?",
                "Pada pendapat anda, adakah kandungan kursus dirancang dan disusun dengan baik?",
                "Adakah cara/teknik penyampaian kursus ini memenuhi ekspektasi/jangkaan anda? - Nota",
                "Adakah cara/teknik penyampaian kursus ini memenuhi ekspektasi/jangkaan anda? - Video",
                "Adakah cara/teknik penyampaian kursus ini memenuhi ekspektasi/jangkaan anda? - Infografik",
                "Adakah cara/teknik penyampaian kursus ini memenuhi ekspektasi/jangkaan anda? - Audio",
                "Adakah soalan kuiz dan ujian yang disediakan membantu dalam proses pembelajaran?",
                "Pada pendapat anda, apakah tahap pengetahuan/kemahiran yang diperlukan untuk melengkapkan kursus ini:",
                "Cadangan Penambahbaikan platform Hajj e-Learning (jika ada)"
            ];

            let data4Excel = this.surveyAnswers.map((answer, ind) => {
                return [
                this.selectedRole,
                ind + 1,
                answer.attributes.users_permissions_user.data?.attributes.registration_number || "-",
                answer.attributes.users_permissions_user.data?.attributes.email || "-",
                answer.attributes.survey_question_answer[0].answer || "-",
                answer.attributes.survey_question_answer[1].answer || "-",
                answer.attributes.survey_question_answer[2].answer || "-",
                answer.attributes.survey_question_answer[3].answer || "-",
                answer.attributes.survey_question_answer[4].answer || "-",
                answer.attributes.survey_question_answer[5].answer || "-",
                answer.attributes.survey_question_answer[6].answer || "-",
                answer.attributes.survey_question_answer[7].answer || "-",
                answer.attributes.survey_question_answer[8].answer || "-",
                answer.attributes.survey_question_answer[9].answer || "-",
                answer.attributes.survey_question_answer[10].answer || "-",
                ]
            })

            data4Excel = [headers, ...data4Excel]
            const ws = XLSX.utils.aoa_to_sheet(data4Excel);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
            /* generate file and send to client */
            XLSX.writeFile(wb, "laporan_penilaian.xlsx");
            }
    },
    async mounted(){
        this.isLoading = true

        let filterRoleAccess = ''
        this.roleAccess.forEach((item, ind) => {
            filterRoleAccess += `filters[role_access][$in][${ind}]=${item}&`
        })
        const res = await API.get(`courses?populate=%2A&filters[course_type][$eq]=online&${filterRoleAccess}`)
        this.courses = res.data.data;
        this.selectedCourse = this.courses[0];
        this.selectedRole = 'all';
        this.selectedView = 'all';

        //no need, init with watcher
        // await this.initAllData()

        this.isLoading = false
    }
}
</script>

<style lang="scss" scoped>
@import "./../../assets/scss/color.scss";

.card {
    &.scale-card {
        height: 70px;
        background-color: #f5f6f7;
        border: unset;
        font-size: 12px;
    }
}
</style>