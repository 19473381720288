<template>
  <div class="body-container">
    <div class="container mt-3 bg-light p-2">
      <div class="row g-2">
        <div class="col-2 col-custom">
          <div class="card border-0">
            <div class="card-body p-0">
              <div class="px-4 pb-1 pt-3">
                <h5 class="fw-bold">Menu</h5>
              </div>
              <div class="">
                <ul class="list-group">
                  <li
                    v-if="listShow(['admin_hq', 'admin_management'])"
                    :class="
                      isActiveBtn.value == 'Kursus' ||
                      isActiveBtn.value == 'Exam'
                        ? 'list-group-item is-active border-light border-top-0 border-end-0 border-start-0 fw-bold'
                        : 'list-group-item inactive border-light border-top-0 border-end-0 border-start-0'
                    "
                  >
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <div class="d-flex align-items-center">
                        <i class="ri-user-line me-2"></i>
                        <p class="m-0">Bakal Haji</p>
                      </div>
                      <!-- <i class="ri-arrow-right-s-line ms-2"></i> -->
                    </div>
                  </li>
                  <!-- <li
                    @click="isActiveBtn = 'Overview'"
                    :class="
                      isActiveBtn == 'Overview'
                        ? 'list-group-item is-active border-light border-top-0 border-end-0 border-start-0 fw-bold'
                        : 'list-group-item inactive border-light border-top-0 border-end-0 border-start-0'
                    "
                  >
                    <div
                      class="
                        d-flex
                        justify-content-between
                        align-items-center
                        ps-4
                      "
                    >
                      <p class="m-0">Overview</p>
                    </div>
                  </li> -->
                  <li
                    v-if="listShow(['admin_hq', 'admin_management'])"
                    @click="isActiveBtn = { value: 'Kursus', label: 'Kursus' }"
                    :class="
                      isActiveBtn.value == 'Kursus'
                        ? 'list-group-item is-active border-light border-top-0 border-end-0 border-start-0 fw-bold'
                        : 'list-group-item inactive border-light border-top-0 border-end-0 border-start-0'
                    "
                  >
                    <div
                      class="
                        d-flex
                        justify-content-between
                        align-items-center
                        ps-4
                      "
                    >
                      <p class="m-0">Kursus</p>
                      <!-- <i class="ri-arrow-right-s-line ms-2"></i> -->
                    </div>
                  </li>
                  <li
                    v-if="listShow(['admin_hq', 'admin_management'])"
                    @click="isActiveBtn = { value: 'Exam', label: 'Exam' }"
                    :class="
                      isActiveBtn.value == 'Exam'
                        ? 'list-group-item is-active border-light border-top-0 border-end-0 border-start-0 fw-bold'
                        : 'list-group-item inactive border-light border-top-0 border-end-0 border-start-0'
                    "
                  >
                    <div
                      class="
                        d-flex
                        justify-content-between
                        align-items-center
                        ps-4
                      "
                    >
                      <p class="m-0">Ujian</p>
                      <!-- <i class="ri-arrow-right-s-line ms-2"></i> -->
                    </div>
                  </li>
                  <li
                    v-if="listShow(['admin_hq', 'admin_management'])"
                    :class="
                      isActiveBtn.value == 'Senarai' ||
                      isActiveBtn.value == 'KursusPekta' ||
                      isActiveBtn.value == 'UjianPektaKursus' ||
                      isActiveBtn.value == 'UjianPekta' ||
                      isActiveBtn.value == 'Pihtas' ||
                      isActiveBtn.value == 'PenilaianPekta'
                        ? 'list-group-item is-active border-light border-top-0 border-end-0 border-start-0 fw-bold'
                        : 'list-group-item inactive border-light border-top-0 border-end-0 border-start-0'
                    "
                  >
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <div class="d-flex align-items-center">
                        <i class="ri-user-2-line me-2"></i>
                        <p class="m-0">PEKTA</p>
                      </div>
                      <!-- <i class="ri-arrow-right-s-line ms-2"></i> -->
                    </div>
                  </li>
                  <li
                    v-if="listShow(['admin_hq', 'admin_management'])"
                    @click="
                      isActiveBtn = { value: 'Senarai', label: 'Senarai' }
                    "
                    :class="
                      isActiveBtn.value == 'Senarai'
                        ? 'list-group-item is-active border-light border-top-0 border-end-0 border-start-0 fw-bold'
                        : 'list-group-item inactive border-light border-top-0 border-end-0 border-start-0'
                    "
                  >
                    <div
                      class="
                        d-flex
                        justify-content-between
                        align-items-center
                        ps-4
                      "
                    >
                      <p class="m-0">Senarai</p>
                      <!-- <i class="ri-arrow-right-s-line ms-2"></i> -->
                    </div>
                  </li>
                  <li
                    v-if="listShow(['admin_hq', 'admin_management'])"
                    @click="
                      isActiveBtn = {
                        value: 'KursusPekta',
                        label: 'Kursus',
                      }
                    "
                    :class="
                      isActiveBtn.value == 'KursusPekta'
                        ? 'list-group-item is-active border-light border-top-0 border-end-0 border-start-0 fw-bold'
                        : 'list-group-item inactive border-light border-top-0 border-end-0 border-start-0'
                    "
                  >
                    <div
                      class="
                        d-flex
                        justify-content-between
                        align-items-center
                        ps-4
                      "
                    >
                      <p class="m-0">Kursus</p>
                    </div>
                  </li>
                  <li
                    v-if="listShow(['admin_hq', 'admin_management'])"
                    @click="
                      isActiveBtn = {
                        value: 'UjianPektaKursus',
                        label: 'Ujian',
                      }
                    "
                    :class="
                      isActiveBtn.value == 'UjianPektaKursus'
                        ? 'list-group-item is-active border-light border-top-0 border-end-0 border-start-0 fw-bold'
                        : 'list-group-item inactive border-light border-top-0 border-end-0 border-start-0'
                    "
                  >
                    <div
                      class="
                        d-flex
                        justify-content-between
                        align-items-center
                        ps-4
                      "
                    >
                      <p class="m-0">Ujian Kursus</p>
                    </div>
                  </li>
                  <li
                    v-if="listShow(['admin_hq', 'admin_management'])"
                    @click="
                      isActiveBtn = {
                        value: 'UjianPekta',
                        label: 'Ujian',
                      }
                    "
                    :class="
                      isActiveBtn.value == 'UjianPekta'
                        ? 'list-group-item is-active border-light border-top-0 border-end-0 border-start-0 fw-bold'
                        : 'list-group-item inactive border-light border-top-0 border-end-0 border-start-0'
                    "
                  >
                    <div
                      class="
                        d-flex
                        justify-content-between
                        align-items-center
                        ps-4
                      "
                    >
                      <p class="m-0">Ujian Permohonan PEKTA Baru</p>
                    </div>
                  </li>
                  <li
                    v-if="listShow(['admin_hq', 'admin_management'])"
                    :class="
                      isActiveBtn.value == 'SenaraiKatam'
                        ? 'list-group-item is-active border-light border-top-0 border-end-0 border-start-0 fw-bold'
                        : 'list-group-item inactive border-light border-top-0 border-end-0 border-start-0'
                    "
                  >
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <div class="d-flex align-items-center">
                        <i class="ri-user-2-line me-2"></i>
                        <p class="m-0">KATAM</p>
                      </div>
                      <!-- <i class="ri-arrow-right-s-line ms-2"></i> -->
                    </div>
                  </li>
                  <li
                    v-if="listShow(['admin_hq', 'admin_management'])"
                    @click="
                      isActiveBtn = { value: 'SenaraiKatam', label: 'Senarai' }
                    "
                    :class="
                      isActiveBtn.value == 'SenaraiKatam'
                        ? 'list-group-item is-active border-light border-top-0 border-end-0 border-start-0 fw-bold'
                        : 'list-group-item inactive border-light border-top-0 border-end-0 border-start-0'
                    "
                  >
                    <div
                      class="
                        d-flex
                        justify-content-between
                        align-items-center
                        ps-4
                      "
                    >
                      <p class="m-0">Senarai</p>
                      <!-- <i class="ri-arrow-right-s-line ms-2"></i> -->
                    </div>
                  </li>
                  <li
                    v-if="listShow(['admin_hq', 'admin_management'])"
                    :class="
                      isActiveBtn.value == 'SenaraiPetugasHaji'
                        ? 'list-group-item is-active border-light border-top-0 border-end-0 border-start-0 fw-bold'
                        : 'list-group-item inactive border-light border-top-0 border-end-0 border-start-0'
                    "
                  >
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <div class="d-flex align-items-center">
                        <i class="ri-user-2-line me-2"></i>
                        <p class="m-0">Petugas Haji</p>
                      </div>
                      <!-- <i class="ri-arrow-right-s-line ms-2"></i> -->
                    </div>
                  </li>
                  <li
                    v-if="listShow(['admin_hq', 'admin_management'])"
                    @click="
                      isActiveBtn = { value: 'SenaraiPetugasHaji', label: 'Senarai' }
                    "
                    :class="
                      isActiveBtn.value == 'SenaraiPetugasHaji'
                        ? 'list-group-item is-active border-light border-top-0 border-end-0 border-start-0 fw-bold'
                        : 'list-group-item inactive border-light border-top-0 border-end-0 border-start-0'
                    "
                  >
                    <div
                      class="
                        d-flex
                        justify-content-between
                        align-items-center
                        ps-4
                      "
                    >
                      <p class="m-0">Senarai</p>
                      <!-- <i class="ri-arrow-right-s-line ms-2"></i> -->
                    </div>
                  </li>
                  <!-- <li
                    @click="
                      isActiveBtn = {
                        value: 'PenilaianPekta',
                        label: 'Penilaian',
                      }
                    "
                    :class="
                      isActiveBtn.value == 'PenilaianPekta'
                        ? 'list-group-item is-active border-light border-top-0 border-end-0 border-start-0 fw-bold'
                        : 'list-group-item inactive border-light border-top-0 border-end-0 border-start-0'
                    "
                  >
                    <div
                      class="
                        d-flex
                        justify-content-between
                        align-items-center
                        ps-4
                      "
                    >
                      <p class="m-0">Penilaian</p>
                    </div>
                  </li> -->
                  <!-- <li
                    @click="
                      isActiveBtn = {
                        value: 'Pihtas',
                        label: 'PIHTAS',
                      }
                    "
                    :class="
                      isActiveBtn.value == 'Pihtas'
                        ? 'list-group-item is-active border-light border-top-0 border-end-0 border-start-0 fw-bold'
                        : 'list-group-item inactive border-light border-top-0 border-end-0 border-start-0'
                    "
                  >
                    <div
                      class="
                        d-flex
                        justify-content-between
                        align-items-center
                        ps-4
                      "
                    >
                      <p class="m-0">PIHTAS</p>
                    </div>
                  </li> -->
                  <li
                    v-if="listShow(['admin_hq','admin_management', 'admin_state'])"
                    @click="
                      isActiveBtn = {
                        value: 'Manual Report',
                        label: 'Laporan Manual',
                      }
                    "
                    :class="
                      isActiveBtn.value == 'Manual Report'
                        ? 'list-group-item is-active border-light border-top-0 border-end-0 border-start-0 fw-bold'
                        : 'list-group-item inactive border-light border-top-0 border-end-0 border-start-0'
                    "
                  >
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <div class="d-flex align-items-center">
                        <i class="ri-pages-line me-2"></i>
                        <p class="m-0">Laporan Manual</p>
                      </div>
                      <!-- <i class="ri-arrow-right-s-line ms-2"></i> -->
                    </div>
                  </li>
                  <li
                    v-if="listShow(['admin_hq','admin_management'])"
                    @click="
                      isActiveBtn = { value: 'Survey', label: 'Survey' }"
                    :class="
                      isActiveBtn.value == 'Survey'
                        ? 'list-group-item is-active border-light border-top-0 border-end-0 border-start-0 fw-bold'
                        : 'list-group-item inactive border-light border-top-0 border-end-0 border-start-0'
                    "
                  >
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <div class="d-flex align-items-center">
                        <i class="ri-pages-line me-2"></i>
                        <p class="m-0">Penilaian</p>
                      </div>
                      <!-- <i class="ri-arrow-right-s-line ms-2"></i> -->
                    </div>
                  </li>
                  <li
                    v-if="listShow(['admin_hq', 'superadmin'])"
                    @click="
                      isActiveBtn = {
                        value: 'Audit Log',
                        label: 'Log Audit',
                      }
                    "
                    :class="
                      isActiveBtn.value == 'Audit Log'
                        ? 'list-group-item is-active border-light border-top-0 border-end-0 border-start-0 fw-bold'
                        : 'list-group-item inactive border-light border-top-0 border-end-0 border-start-0'
                    "
                  >
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <div class="d-flex align-items-center">
                        <i class="ri-route-line me-2"></i>
                        <p class="m-0">Audit Log</p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-10">
          <div class="card border-0">
            <!-- BAKAL HAJI -->
            <div class="card-body d-flex justify-content-between align-items-center">
              <h5 class="fw-bold" v-if="isActiveBtn.value == 'Senarai'">
                Laporan PEKTA
              </h5>
              <h5 class="fw-bold" v-if="isActiveBtn.value == 'Kursus'">
                Laporan Kursus Bakal Haji
              </h5>
              <h5 class="fw-bold" v-if="isActiveBtn.value == 'KursusPekta'">
                Laporan Kursus PEKTA
              </h5>
              <h5 class="fw-bold" v-if="isActiveBtn.value == 'Exam'">
                Laporan Ujian Atas Talian
              </h5>
              <h5 class="fw-bold" v-if="isActiveBtn.value == 'Survey'">
                Laporan Penilaian Kursus
              </h5>
              <h5 class="fw-bold" v-if="isActiveBtn.value == 'UjianPekta'">
                Laporan Ujian PEKTA
              </h5>
              <h5
                class="fw-bold"
                v-if="isActiveBtn.value == 'UjianPektaKursus'"
              >
                Laporan Ujian Kursus PEKTA
              </h5>
              <h5 class="fw-bold" v-if="isActiveBtn.value == 'PenilaianPekta'">
                Laporan Penilaian PEKTA
              </h5>
              <h5 class="fw-bold" v-if="isActiveBtn.value == 'Pihtas'">
                Report Markah Ujian
              </h5>
              <h5 class="fw-bold" v-if="isActiveBtn.value == 'SenaraiKatam'">
                Laporan KATAM
              </h5>
              <h5 class="fw-bold" v-if="isActiveBtn.value == 'SenaraiPetugasHaji'">
                Laporan Petugas Haji
              </h5>
              <h5 class="fw-bold" v-if="isActiveBtn.value == 'Manual Report'">
                Laporan Manual
              </h5>

              <div>
                <div v-if="isActiveBtn.value == 'Kursus' || isActiveBtn.value == 'Exam' || isActiveBtn.value == 'Survey'" class="double-val-label me-1">
                  <span>Jumlah Bakal Haji yang telah log masuk</span>
                  <span class="success">{{ BHnumber }}</span>
                </div>

                <div v-if="isActiveBtn.value == 'KursusPekta' || isActiveBtn.value == 'PenilaianPekta' || isActiveBtn.value == 'Senarai' || isActiveBtn.value == 'UjianPektaKursus' || isActiveBtn.value == 'UjianPekta'" class="double-val-label me-1 mt-1">
                  <span>Jumlah PEKTA yang telah log masuk</span>
                  <span class="success">{{ PektaNumber }}</span>
                </div>
              </div>
            </div>
            <!-- Content -->
            <kursus-bakal-haji v-if="isActiveBtn.value == 'Kursus'" />
            <ujian-bakal-haji v-if="isActiveBtn.value == 'Exam'" />
            <!-- PEKTA -->
            <senarai-pekta v-if="isActiveBtn.value == 'Senarai'" />
            <ujian-pekta-kursus
              v-if="isActiveBtn.value == 'UjianPektaKursus'"
            />
            <ujian-pekta v-if="isActiveBtn.value == 'UjianPekta'" />
            <kursus-pekta v-if="isActiveBtn.value == 'KursusPekta'" />
            <pihtas v-if="isActiveBtn.value == 'Pihtas'" />

            <!-- KATAM -->
            <senarai-katam v-if="isActiveBtn.value == 'SenaraiKatam'" />

            <!-- Petugas Haji -->
            <senarai-petugas-haji v-if="isActiveBtn.value == 'SenaraiPetugasHaji'" />

            <!-- REPORT -->
            <manual-report v-if="isActiveBtn.value == 'Manual Report'" />

            <div v-if="isActiveBtn.value == 'Survey'">
              <div class="card border-0">
                <div class="card-body">
                  <survey-comp :roleAccess="['bh', 'public']" />
                </div>
              </div>
            </div>
            <audit-log v-if="isActiveBtn.value == 'Audit Log'" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import API from "../../utils/API";
import overviewChart from "./Chart/overviewChart.vue";
import Helper from "../../utils/helper";
import ManualReport from "./DashboardContent/ManualReport.vue";
import SurveyComp from "./../../components/dashboard/Survey.vue";
import AuditLog from "@/views/admin/DashboardContent/AuditLog";
import KursusBakalHaji from "./DashboardContent/KursusBakalHaji.vue";
import KursusPekta from "./DashboardContent/KursusPekta.vue";
import UjianBakalHaji from "./DashboardContent/UjianBakalHaji.vue";
import UjianPektaKursus from "./DashboardContent/UjianPektaKursus.vue";
import UjianPekta from "./DashboardContent/UjianPekta.vue";
import Pihtas from "./DashboardContent/Pihtas.vue";
import SenaraiPekta from "./DashboardContent/SenaraiPekta.vue";
import SenaraiKatam from "./DashboardContent/SenaraiKatam.vue";
import SenaraiPetugasHaji from "./DashboardContent/SenaraiPetugasHaji.vue";
import { mapGetters } from "vuex";

export default {
  name: "Dashboard",
  components: {
    overviewChart,
    ManualReport,
    SurveyComp,
    KursusBakalHaji,
    UjianBakalHaji,
    KursusPekta,
    UjianPektaKursus,
    UjianPekta,
    Pihtas,
    SenaraiPekta,
    SenaraiKatam,
    SenaraiPetugasHaji,
    AuditLog,
  },
  data() {
    return {
      selectedCourse: null,
      helper: Helper,
      isActiveBtn: {
        value: "",
        label: "",
      },
      answeredSurvey: [],
      BHnumber: 0,
      PektaNumber: 0,
      PetugasNumber: 0,
    };
  },
  watch: {
    isActiveBtn: {
      handler() {
        this.searchingUserPekta = "";
        this.searchingUser = "";
        this.searchingPekta = "";
        this.searchingExam = "";
        this.searchingSurvey = "";
        this.searchingPektaSenaraiBaru = "";
        this.searchingPektaSenaraiDiDaftar = "";
      },
    },
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  methods: {
    listShow(roles) {
      return roles.find((role) => role == this.currentUser.admin_type);
    },
    findSurveyName() {
      return this.searchingSurvey.length > 0
        ? this.answeredSurvey.filter((x) =>
            x.attributes.course.data.attributes.course_name
              .toLowerCase()
              .includes(this.searchingSurvey)
          )
        : this.answeredSurvey;
    },
    suveryArr(arr) {
      return arr.attributes.survey_question_answer
        .filter((x) => x.question != "Negeri")
        .filter((x) => x.question != "Tahap Pendidikan")
        .filter((x) => x.question != "Umur")
        .filter((x) => x.question != "Jenis Pekerjaan")
        .filter(
          (x) =>
            x.question !=
            "Adakah cara/teknik penyampaian kursus ini memenuhi ekspektasi/jangkaan anda? - Infografik"
        )
        .filter(
          (x) =>
            x.question !=
            "Pada pendapat anda, apakah tahap pengetahuan/kemahiran yang diperlukan untuk melengkapkan kursus ini:"
        )
        .filter(
          (x) =>
            x.question !=
            "Cadangan Penambahbaikan platform Hajj e-Learning (jika ada)"
        )
        .filter(
          (x) =>
            x.question !=
            "Adakah soalan kuiz dan ujian yang disediakan membantu dalam proses pembelajaran?"
        );
    },
    getAnsweredSurvey() {
      API.get(
        "answered-surveys?populate=course&populate=survey_question_answer&populate=users_permissions_user"
      )
        .then((response) => {
          // console.log(response.data.data);
          this.answeredSurvey = response.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getBH(){
      API.get(
        `custom/total-loggedin-users?role=bh`
      )
        .then((response) => {
          this.BHnumber = response.data.count;
        })
    },

    getPekta(){
      API.get(
        `custom/total-loggedin-users?role=pekta`
      )
        .then((response) => {
          this.PektaNumber = response.data.count;
        })
    },

    getPetugas(){
      API.get(
        `custom/total-loggedin-users?role=petugas`
      )
        .then((response) => {
          this.PetugasNumber = response.data.count;
        })
    }
    
  },
  async mounted() {
    console.log(this.currentUser.admin_type)

    if (this.currentUser.admin_type === 'superadmin') {
      console.log('audit log')

      this.isActiveBtn = {
        value: 'Audit Log',
        label: 'Log Audit'
      }
    }
    else if (this.currentUser.admin_type === 'admin_state') {
      this.isActiveBtn = {
        value: "Manual Report",
        label: "Laporan Manual",
      }
    }
    else {
      this.isActiveBtn = {
        value: "Kursus",
        label: "Kursus",
      }
    }

    // this.activeTabSenarai = "Berdaftar";
    this.getAnsweredSurvey();
    this.getBH();
    this.getPekta();
    this.getPetugas();
    // this.getQuestionSurvey();
  },
};
</script>

<style scoped>
.body-container {
  min-height: calc(100vh - 170px);
}

.list-group-item {
  cursor: pointer;
}

.inactive {
  color: #9aa0ad;
}

.is-active {
  color: #4ca022;
}

.card-custom {
  position: relative;
  display: block;
  height: 40px;
}

.img-container {
  position: inherit;
  display: block;
  height: 100%;
  width: 100%;
  background-image: url("../../assets/img/file-icon.svg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
}

.col-3.col-custom {
  min-width: 250px !important;
}

.input-custom {
  width: 200px;
}

.tableFixHead {
  overflow: auto;
  height: 500px;
}
.tableFixHead thead th {
  position: sticky;
  top: 0;
  z-index: 1;
}

/* Just common table stuff. Really. */
table {
  border-collapse: collapse;
  width: 100%;
}
th,
td {
  padding: 8px 16px;
}
th {
  background: #f9fafb;
}

::-webkit-scrollbar {
  display: none !important;
}

.double-val-label {
	display: table;
}

.double-val-label>span {
	background-color: #656565;
	color: #ffffff;
	display: table-cell;
	font-size: 0.9em;
	font-weight: 400;
	line-height: 1;
	padding: .3em .6em;
	text-align: center;
	vertical-align: baseline;
	white-space: nowrap;
}

.double-val-label>span:first-child {
	border-bottom-left-radius: 0.25em;
	border-top-left-radius: .25em;
}

.double-val-label>span:nth-child(2) {
	border-bottom-right-radius: 0.25em;
	border-top-right-radius: .25em;
}


.double-val-label>span.success {
	background-color: #5cb85c;
}
</style>

