<template>
  <div>
    <div class="card border-0">
      <div class="card-body">
        <div class="d-flex align-items-center">
          <p class="me-2 mb-0 fs-6">Pilih Kursus:</p>
          <select
            class="form-select"
            aria-label="Default select example"
            style="width: 240px"
            v-model="selected"
          >
            <option
              v-for="course in courses"
              :key="course.id"
              :value="course.id"
            >
              {{ course.course_name }}
            </option>
          </select>
        </div>
      </div>
      <div
        class="card-body border-bottom border-4 border-light"
        v-if="chartData != null"
      >
        <overview-chart :id="'Exam'" :dataSource="chartData" />
      </div>
      <div
        v-else
        class="card-body border-bottom border-4 border-light w-100 d-flex position-relative justify-content-center align-items-center"
        style="height: 394px"
      >
        <div class="text-center">
          <div class="spinner-grow text-muted" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <p class="text-muted small mt-2">Sila tunggu. Memuatkan data...</p>
        </div>
      </div>
      <div class="card-body">
        <div class="d-flex justify-content-between align-items-center mb-2">
          <div class="d-flex">
            <input
              class="form-control me-2 input-custom"
              placeholder="Cari ..."
              v-model="searching"
            />
            <div class="dropdown me-2">
              <button
                class="btn btn-sm border dropdown-toggle"
                type="button"
                id="dropdownMenuFilter"
                data-bs-toggle="dropdown"
                data-bs-auto-close="outside"
                aria-expanded="false"
              >
                Saring
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuFilter">
                <li>
                  <a
                    class="dropdown-item text-reset"
                    data-bs-toggle="collapse"
                    href="#collapseStateFilter"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseStateFilter"
                    >Negeri</a
                  >
                  <div class="collapse" id="collapseStateFilter">
                    <div class="p-2 mb-2">
                      <select class="form-select" v-model="filterOption.state">
                        <option :value="null" disabled selected hidden>
                          Pilih Negeri
                        </option>
                        <option
                          v-for="state in states"
                          :key="state"
                          :value="state"
                        >
                          {{ state }}
                        </option>
                      </select>
                    </div>
                  </div>
                </li>
                <li>
                  <a
                    class="dropdown-item text-reset"
                    data-bs-toggle="collapse"
                    href="#collapseGenderFilter"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseGenderFilter"
                    >Jantina</a
                  >
                  <div class="collapse" id="collapseGenderFilter">
                    <div class="p-2 mb-2">
                      <select class="form-select" v-model="filterOption.gender">
                        <option :value="null" disabled selected hidden>
                          Pilih Jantina
                        </option>
                        <option value="lelaki">Lelaki</option>
                        <option value="perempuan">Perempuan</option>
                      </select>
                    </div>
                  </div>
                </li>
                <li>
                  <a
                    class="dropdown-item text-reset"
                    data-bs-toggle="collapse"
                    href="#collapseStatusFilter"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseStatusFilter"
                    >Status</a
                  >
                  <div class="collapse" id="collapseStatusFilter">
                    <div class="p-2 mb-2">
                      <select class="form-select" v-model="filterOption.status">
                        <option :value="null" disabled selected hidden>
                          Pilih Status
                        </option>
                        <option value="belum_mula">Belum Mula</option>
                        <option value="lulus">Lulus</option>
                      </select>
                    </div>
                  </div>
                </li>
                <li>
                  <a
                    class="dropdown-item text-reset"
                    data-bs-toggle="collapse"
                    href="#collapseAgeFilter"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseAgeFilter"
                    >Umur</a
                  >
                  <div class="collapse" id="collapseAgeFilter">
                    <div class="d-flex p-2">
                      <input
                        class="form-control me-2 input-custom"
                        placeholder="Min umur"
                        type="number"
                        v-model="filterOption.age.min"
                      />
                      <input
                        class="form-control me-2 input-custom"
                        placeholder="Max umur"
                        type="number"
                        v-model="filterOption.age.max"
                      />
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <button
              v-if="
                filterOption.state ||
                filterOption.gender ||
                filterOption.status ||
                filterOption.age.min ||
                filterOption.age.max
              "
              class="btn btn-sm border"
              type="button"
              @click="
                () => {
                  filterOption.state = null;
                  filterOption.gender = null;
                  filterOption.status = null;
                  filterOption.age.min = null;
                  filterOption.age.max = null;
                }
              "
            >
              Set semula
            </button>
          </div>
          <div class="d-flex align-items-center">
            <button
              type="button"
              class="btn btn-sm border me-2"
              @click="handleDownload"
              :disabled="findExamUsers().length == 0"
            >
              Muat turun
            </button>
            <button
              type="button"
              class="btn btn-sm border"
              @click="handlePrint"
              :disabled="findExamUsers().length == 0"
            >
              Cetak
            </button>
          </div>
        </div>
        <div class="tableFixHead">
          <div class="d-flex flex-row-reverse mt-3 mb-2 me-1">
            <div class="justify-content-center">
              <small class="text-muted"
                >Jumlah pengguna: {{ findExamUsers().length }}</small
              >
            </div>
          </div>
          <table class="table">
            <thead>
              <tr>
                <th style="width: 30px">#</th>
                <th style="width: 350px">Nama</th>
                <th style="width: 100px">Umur</th>
                <th style="width: 100px">Jantina</th>
                <th style="width: 100px">Markah</th>
                <th style="width: 100px">Status</th>
              </tr>
            </thead>
            <tbody class="border-0">
              <tr
                class="border-light"
                v-for="(exam, i) in findExamUsers()"
                :key="i"
              >
                <td>{{ i + 1 }}</td>
                <!-- <td>{{ exam.user_username }}</td> -->
                <td>{{ exam.user_fullname }}</td>
                <td>{{ exam.user_age }}</td>
                <td>{{ exam.user_gender }}</td>
                <td>{{ exam.course_mark ? exam.course_mark : 0 }}/100</td>
                <td>
                  <div
                    v-if="exam.course_mark_status == 'failed'"
                    class="badge rounded-pill bg-danger text-white"
                  >
                    Gagal
                  </div>
                  <div
                    v-if="exam.course_mark_status == null"
                    class="badge rounded-pill bg-light text-muted"
                  >
                    Belum Mula
                  </div>
                  <div
                    v-if="
                      exam.course_mark_status != null &&
                      exam.course_mark_status != 'failed'
                    "
                    class="badge rounded-pill bg-success text-light"
                  >
                    Lulus
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "../../../utils/API";
import overviewChart from "./../Chart/overviewChart.vue";
import * as XLSX from "xlsx";
import printJS from "print-js";

export default {
  name: "UjianBakalHaji",
  components: {
    overviewChart,
  },
  data() {
    return {
      selected: "",
      courses: [],
      chartAllData: [],
      chartData: null,
      searching: "",
      exams: [],
      states: [
        "Johor",
        "Kedah",
        "Kelantan",
        "Melaka",
        "Negeri Sembilan",
        "Pahang",
        "Perak",
        "Perlis",
        "Pulau Pinang",
        "Sarawak",
        "Selangor",
        "Terengganu",
        "Kuala Lumpur",
        "Labuan",
        "Sabah",
        "Putrajaya",
      ],
      filterOption: {
        state: null,
        gender: null,
        age: {
          min: null,
          max: null,
        },
        status: null,
      },
    };
  },
  watch: {
    selected: {
      handler() {
        this.chartData = null;
        this.getExam(this.selected);
      },
      immediate: false,
    },
    // filterOption: {
    //   handler(_val){
    //     console.log('val :', _val)
    //   },
    //   deep: true
    // }
  },
  methods: {
    handleDownload() {
      let headers = ["#", "Nama", "Umur", "Jantina", "Markah", "Status"];
      let rowData = this.findExamUsers().map((exam, index) => {
        const status =
          exam.course_mark_status == "failed"
            ? "Gagal"
            : exam.course_mark_status == null
            ? "Belum mula"
            : "Lulus";
        return [
          index + 1,
          exam.user_username ? exam.user_username : "-",
          exam.user_age ? exam.user_age : "-",
          exam.user_gender ? exam.user_gender : "-",
          exam.course_mark ? exam.course_mark : "-",
          status,
        ];
      });
      let data = [headers, ...rowData];
      const ws = XLSX.utils.aoa_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
      /* generate file and send to client */
      XLSX.writeFile(wb, "sheetjs.xlsx");
    },
    handlePrint() {
      printJS({
        printable: this.findExamUsers().map((exam, index) => {
          const status =
            exam.course_mark_status == "failed"
              ? "Gagal"
              : exam.course_mark_status == null
              ? "Belum mula"
              : "Lulus";
          return {
            index: index + 1,
            nama: exam.user_username ? exam.user_username : "-",
            umur: exam.user_age ? exam.user_age : "-",
            jantina: exam.user_gender ? exam.user_gender : "-",
            markah: exam.course_mark ? exam.course_mark : "-",
            status: status,
          };
        }),
        properties: [
          { field: "index", displayName: "#" },
          { field: "nama", displayName: "Nama" },
          { field: "umur", displayName: "Umur" },
          { field: "jantina", displayName: "Jantina" },
          { field: "markah", displayName: "Markah" },
          { field: "status", displayName: "Status" },
        ],
        type: "json",
      });
    },
    findExamUsers() {
      let finalExams = this.exams;

      if (this.searching.length > 0) {
        finalExams = finalExams.filter((x) => {
          if (x.user_fullname != null)
            return x.user_fullname.toLowerCase().includes(this.searching.toLowerCase());
          else return null;
        });
      }

      // if(this.filterOption.state){
      //   //
      // }

      if (this.filterOption.gender) {
        finalExams = finalExams.filter((x) => {
          if (x.user_gender != null)
            return x.user_gender
              .toLowerCase()
              .includes(this.filterOption.gender.toLowerCase());
        });
      }

      if (this.filterOption.status) {
        finalExams = finalExams.filter((x) => {
          if (
            this.filterOption.status == "lulus" &&
            x.course_mark_status != null
          )
            return x.course_mark_status == "pass";
          else if (this.filterOption.status == "belum_mula")
            return x.course_mark_status == null;
        });
      }

      if (this.filterOption.age.min && this.filterOption.age.max) {
        finalExams = finalExams.filter((x) => {
          if (x.user_age != null)
            return (
              x.user_age >= this.filterOption.age.min &&
              x.user_age <= this.filterOption.age.max
            );
        });
      }

      return finalExams;
    },
    getCourses() {
      this.courses = this.chartAllData.map(x => { return { id:x.course_id, course_name: x.course_name } })
      this.selected = this.courses[0].id
      // API.get(
      //   "courses?[populate]=users&filters[$and][0][role_access][$eq]=bh&filters[$and][0][role_access][$eq]=public&filters[$and][0][course_type][$eq]=online"
      // )
      //   .then((response) => {
      //     if (response.data.data.length > 0) {
      //       this.selected = response.data.data[0].id;
      //       this.courses = response.data.data;
      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
    },
    getExam(id) {
      if (id != null) {
        API.get(`custom/report-course-exam/${id}`)
          .then((response) => {
            const newData = response.data.report_data;
            let pdata20 = newData.filter((x) => x.course_mark < 20);

            let pdata40 = newData.filter((x) => {
              if (x.course_mark > 20 && x.course_mark < 40) return x;
            });
            let pdata80 = newData.filter((x) => {
              if (x.course_mark > 40 && x.course_mark < 60) return x;
            });
            let pdata100 = newData.filter((x) => {
              if (x.course_mark > 60 && x.course_mark < 100) return x;
            });
            const newItem = {
              labels: ["0-20", "20-40", "60-80", "80-100"],
              data: [
                pdata20.length,
                pdata40.length,
                pdata80.length,
                pdata100.length,
              ],
            };
            // this.chartData = newItem;
            this.exams = response.data.report_data;
          })
          .catch((err) => {
            console.log(err);
          });

        //set graph data
        let foundGData = this.chartAllData.find(x => x.course_id == id)
        this.chartData = {
          labels: foundGData? foundGData.labels : null,
          data: foundGData? foundGData.data : null
        }
      }
    },
    async getGraphData(){
      const res01 = await API.post(`custom/fetch-graph-data`, { graph_type: "bh_ujian" })
      this.chartAllData = res01.data.result

      this.getCourses();
    }
  },
  mounted() {
    this.getGraphData()
    // this.getCourses();
  },
};
</script>
<style scoped>
.body-container {
  min-height: calc(100vh - 170px);
}

.list-group-item {
  cursor: pointer;
}

.inactive {
  color: #9aa0ad;
}

.is-active {
  color: #4ca022;
}

.card-custom {
  position: relative;
  display: block;
  height: 40px;
}

.img-container {
  position: inherit;
  display: block;
  height: 100%;
  width: 100%;
  background-image: url("../../../assets/img/file-icon.svg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
}

.col-3.col-custom {
  min-width: 250px !important;
}

.input-custom {
  width: 200px;
}

.tableFixHead {
  overflow: auto;
  min-height: 200px;
  max-height: 500px;
}
.tableFixHead thead th {
  position: sticky;
  top: 0;
  z-index: 1;
}

/* Just common table stuff. Really. */
table {
  border-collapse: collapse;
  width: 100%;
}
th,
td {
  padding: 8px 16px;
}
th {
  background: #f9fafb;
}

::-webkit-scrollbar {
  display: none !important;
}
</style>
