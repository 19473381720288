<template>
  <div>
    <div class="card-body">
      <div class="d-flex justify-content-between align-items-center mb-2">
        <input
          class="form-control me-2 input-custom"
          placeholder="Search ..."
          v-model="searching"
        />
        <div class="d-flex align-items-center">
          <button
            type="button"
            class="btn btn-sm border me-2"
            @click="handleDownload"
            :disabled="findPekta().length == 0"
          >
            Download
          </button>
          <button
            type="button"
            class="btn btn-sm border"
            @click="handlePrint"
            :disabled="findPekta().length == 0"
          >
            Print
          </button>
        </div>
      </div>
      <div class="tableFixHead">
        <table class="table">
          <thead>
            <tr>
              <th style="width: 30px">#</th>
              <th style="width: 300px">Nama</th>
              <th style="width: 100px">Umur</th>
              <th style="width: 100px">Jantina</th>
              <th style="width: 400px">Kursus</th>
              <th style="width: 100px">Markah</th>
              <th style="width: 100px">Status</th>
            </tr>
          </thead>
          <tbody class="border-0">
            <tr
              class="border-light"
              v-for="(user, idx) in findPekta()"
              :key="idx"
            >
              <td>{{ idx + 1 }}</td>
              <td>
                {{
                  user.attributes.user && user.attributes.user.username
                    ? user.attributes.user.username
                    : "-"
                }}
              </td>
              <td>
                {{
                  user.attributes.user && user.attributes.user.age
                    ? user.attributes.user.age
                    : "-"
                }}
              </td>
              <td>
                {{
                  user.attributes.user && user.attributes.user.gender
                    ? user.attributes.user.gender
                    : "-"
                }}
              </td>
              <td>
                <div
                  v-if="
                    user.attributes.user &&
                    user.attributes.user.user_course_completions.length == 0
                  "
                >
                  -
                </div>
                <div
                  v-else-if="
                    user.attributes.user &&
                    user.attributes.user.user_course_completions
                  "
                  class="d-flex justify-content-between align-items-center"
                  v-for="(item, i) in user.attributes.user
                    .user_course_completions"
                  :key="i"
                >
                  <div class="me-2 mb-2" style="font-size: 0.8rem">
                    {{ item.course.data.attributes.course_name }}
                  </div>
                  <div
                    :class="
                      item.completion_percentage == 100
                        ? 'badge rounded-pill bg-success text-white mb-2'
                        : item.completion_percentage < 50
                        ? 'badge rounded-pill bg-light text-muted mb-2'
                        : 'badge rounded-pill bg-warning text-white mb-2'
                    "
                    style="font-size: 0.8rem"
                  >
                    {{ item.completion_percentage }}%
                  </div>
                </div>
              </td>
              <td>-</td>
              <td>-</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import API from "../../../utils/API";
import overviewChart from "./../Chart/overviewChart.vue";
import * as XLSX from "xlsx";
import printJS from "print-js";

export default {
  name: "Pihtas",
  components: {
    overviewChart,
  },
  data() {
    return {
      searching: "",
      pektas: [],
    };
  },
  methods: {
    handleDownload() {
      let headers = [
        "#",
        "Nama",
        "Umur",
        "Jantina",
        "Kursus",
        "Markah",
        "Status",
      ];
      let rowData = this.findPekta().map((user, index) => {
        let kursusData = [];
        if (
          user.attributes.user &&
          user.attributes.user != null &&
          user.attributes.user.user_course_completions
        ) {
          kursusData = user.attributes.user.user_course_completions.map(
            (item) => {
              return `${item.course.data.attributes.course_name} - ${item.completion_percentage}`;
            }
          );
        }
        return [
          index + 1,
          user.attributes.user && user.attributes.user.username
            ? user.attributes.user.username
            : "-",
          user.attributes.user && user.attributes.user.age
            ? user.attributes.user.age
            : "-",
          user.attributes.user && user.attributes.user.gender
            ? user.attributes.user.gender
            : "-",
          user.course_mark ? user.course_mark : 0,
          kursusData.join(),
          "-",
          "-",
        ];
      });
      let data = [headers, ...rowData];
      const ws = XLSX.utils.aoa_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
      /* generate file and send to client */
      XLSX.writeFile(wb, "sheetjs.xlsx");
    },
    handlePrint() {
      printJS({
        printable: this.findPekta().map((user, index) => {
          let kursusData = [];
          if (
            user.attributes.user &&
            user.attributes.user != null &&
            user.attributes.user.user_course_completions
          ) {
            kursusData = user.attributes.user.user_course_completions.map(
              (item) => {
                return `${item.course.data.attributes.course_name} - ${item.completion_percentage}`;
              }
            );
          }
          return {
            index: index + 1,
            nama:
              user.attributes.user && user.attributes.user.username
                ? user.attributes.user.username
                : "-",
            umur:
              user.attributes.user && user.attributes.user.age
                ? user.attributes.user.age
                : "-",
            jantina:
              user.attributes.user && user.attributes.user.gender
                ? user.attributes.user.gender
                : "-",
            kursus: kursusData,
            markah: "-",
            status: "-",
          };
        }),
        properties: [
          { field: "index", displayName: "#" },
          { field: "nama", displayName: "Nama" },
          { field: "umur", displayName: "Umur" },
          { field: "jantina", displayName: "Jantina" },
          { field: "kursus", displayName: "Kursus" },
          { field: "markah", displayName: "Markah" },
          { field: "status", displayName: "Status" },
        ],
        type: "json",
      });
    },
    findPekta() {
      return this.searching.length > 0
        ? this.pektas.filter((x) => {
            if (x.attributes.user && x.attributes.user.username)
              return x.attributes.user.username
                .toLowerCase()
                .includes(this.searching);
            else return null;
          })
        : this.pektas;
    },
    getPektas() {
      API.get(
        `pektas?populate[0]=user&populate[1]=user.course_mark&populate[2]=user.user_course_completions&populate[3]=user.user_course_completions.course&populate[4]=user.course_mark.course&populate[5]=user.role`
      )
        .then((response) => {
          this.pektas = response.data.data
            .filter((x) => x.role_access == "Pekta")
            .filter((x) => x.is_pihtas == true)
            .map((x) => {
              let user = null;
              if (x.attributes.user && x.attributes.user.data != null) {
                user = x.attributes.user.data.attributes;
              }
              return {
                ...x,
                attributes: {
                  ...x.attributes,
                  user,
                },
              };
            });
        })
        .catch((err) => {});
    },
  },
  mounted() {
    this.getPektas();
  },
};
</script>

<style scoped>
.body-container {
  min-height: calc(100vh - 170px);
}

.list-group-item {
  cursor: pointer;
}

.inactive {
  color: #9aa0ad;
}

.is-active {
  color: #4ca022;
}

.card-custom {
  position: relative;
  display: block;
  height: 40px;
}

.img-container {
  position: inherit;
  display: block;
  height: 100%;
  width: 100%;
  background-image: url("../../../assets/img/file-icon.svg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
}

.col-3.col-custom {
  min-width: 250px !important;
}

.input-custom {
  width: 200px;
}

.tableFixHead {
  overflow: auto;
  min-height: 200px;
  max-height: 500px;
}
.tableFixHead thead th {
  position: sticky;
  top: 0;
  z-index: 1;
}

/* Just common table stuff. Really. */
table {
  border-collapse: collapse;
  width: 100%;
}
th,
td {
  padding: 8px 16px;
}
th {
  background: #f9fafb;
}

::-webkit-scrollbar {
  display: none !important;
}
</style>
